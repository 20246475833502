export function formatBoldText(text) {
  return text
    .replaceAll("[p]", '<p style="font-weight: bold">')
    .replaceAll("[/p]", "</p>");
}

export function ensureHttpPrefix(path) {
  return path.startsWith("http")
    ? path
    : window.location.origin + "/api" + path;
}

export function getQueryParamValue(paramName) {
  var queryString = window.location.hash || window.location.search;
  var queryIndex = queryString.indexOf("?");
  var queryParams = queryString.substring(queryIndex + 1);
  console.log(queryParams);
  var pairs = queryParams.split("&");
  for (var i = 0; i < pairs.length; i++) {
    var pair = pairs[i].split("=");
    if (pair[0] == paramName) {
      return pair[1];
    }
  }
  return "";
}
