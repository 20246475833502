<template>
  <div>
    <van-dialog :showConfirmButton="false" v-model="show">
      <div class="title">
        <h1>{{ $t("share.shareFriend") }}</h1>
        <van-icon name="cross" @click="show = false" />
      </div>
      <div class="info">
        {{ $t("share.info") }}
      </div>
      <div class="defi-share">
        <h3>{{ $t("share.myShareLink") }}</h3>
        <van-field v-model="link" center clearable />
        <van-button
          v-clipboard:copy="link"
          v-clipboard:success="onCopy"
          v-clipboard:error="onError"
          type="primary"
          block
        >
          {{ $t("share.copy") }}
        </van-button>
      </div>
    </van-dialog>
  </div>
</template>

<script>

export default {
  name: "Share",
  components: {},
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    path: {
      type: String,
      default: "",
    },
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    myAccount() {
      return this.$store.getters["homeData/myAccount"];
    },
    link() {
      const origin = window.location.origin + this.path;
      return `${origin}?inviteCode=${this.myAccount?.inviteCode}`;
    },
  },
  methods: {
    onCopy() {
      this.$toast.success("Copy successfully!");
    },
    onError() {
      this.$toast.fail("Copy failed");
    },
  },
};
</script>

<style scoped>
body,
html {
  width: 100%;
  -webkit-overflow-scrolling: touch;
  -webkit-user-select: none;
  user-select: none;
}

.clearfix:after {
  display: block;
  clear: both;
  content: "";
  visibility: hidden;
  height: 0;
}

button,
input,
select,
textarea {
  outline: none;
}

.back-enter-active,
.back-leave-active,
.forward-enter-active,
.forward-leave-active {
  will-change: transform;
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
  position: absolute;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-perspective: 1000;
  perspective: 1000;
}

.back-enter {
  opacity: 0.75;
  -webkit-transform: translate3d(-100%, 0, 0) !important;
  transform: translate3d(-100%, 0, 0) !important;
}

.back-enter-active {
  z-index: -1 !important;
}

.back-enter-active,
.back-leave-active {
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}

.back-leave-active,
.forward-enter {
  -webkit-transform: translate3d(100%, 0, 0) !important;
  transform: translate3d(100%, 0, 0) !important;
}

.forward-enter-active,
.forward-leave-active {
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}

.forward-leave-active {
  z-index: -1;
  opacity: 0.65;
  -webkit-transform: translate3d(-100%, 0, 0) !important;
  transform: translate3d(-100%, 0, 0) !important;
}

input:-webkit-autofill,
input:-webkit-autofill:active,
input:-webkit-autofill:focus,
input:-webkit-autofill:hover {
  -webkit-transition-delay: 99999s;
  -webkit-transition: color 99999s ease-out, background-color 99999s ease-out;
}

.van-empty .van-empty__image {
  width: 1.86667rem;
  height: 1.86667rem;
}

.van-empty .van-empty__image img {
  height: auto;
}

.van-nav-bar {
  background: #181e24;
  color: #fff;
  padding-top: 0.26667rem;
  padding-left: 0.26667rem;
}

.van-nav-bar img {
  width: 0.45333rem;
}

.van-hairline--bottom:after {
  border: none;
}

.van-nav-bar-left-area {
  padding-top: 0.26667rem;
  padding-bottom: 0.26667rem;
  padding-right: 0.26667rem;
}

.van-nav-bar--fixed + div,
.van-nav-bar--fixed + form {
  margin-top: 1.46667rem;
}

.van-toast--fail,
.van-toast--success {
  width: 80% !important;
}

.flex-between {
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}

.flex-between,
.flex-start {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.flex-start {
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
}

.flex-end {
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}

.flex-center,
.flex-end {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.flex-center {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}

.van-dialog {
  background-color: #fff;
  border-radius: 0.88rem;
}

.defi-share {
  background: #fff;
  padding: 0.53333rem;
  color: #a1a1b3;
}

.defi-share h3 {
  color: #6d4dda;
  font-family: Arial MT Bold;
}

.title {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  justify-items: center;
  background: #616af2;
  color: #fff;
}

h3 {
  color: #a1a1b3;
  margin: 0;
}

h1 {
  font-family: Arial MT Bold;
  font-size: 0.4rem;
  color: #fff;
  padding: 0.53333rem 0.8rem;
  margin: 0;
}

.van-button {
  background-color: #616af2;
  border-radius: 0.29333rem;
  font-size: 0.37333rem;
  font-family: Arial MT Bold;
  border: none;
}

.van-cell {
  padding-left: 0;
  padding-right: 0;
  color: #c1b6f8;
  background: #fff;
}

.van-cell:after {
  border: 0;
}

.van-icon-cross:before {
  line-height: 1.44rem;
  margin-right: 0.53333rem;
  color: #fff;
}

.info {
  padding: 0.8rem 0.53333rem 1.6rem 0.53333rem;
  color: #c3c4ca;
  background: #fff;
  font-size: 0.34667rem;
  font-family: ArialMT Regular;
  line-height: 0.66667rem;
}

.van-field__control {
  background: #fff;
  color: #000;
}
</style>
