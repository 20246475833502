import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./i18n";
import Vant from "vant";
import "vant/lib/index.css";
import SvgIcon from "./components/SvgIcon.vue";

Vue.use(Vant);
Vue.component("svg-icon", SvgIcon);

// 初始化Store
store.dispatch("homeData/getHomeData");
store.dispatch("homeData/getNftHomeData");

function gmtFormat(seconds) {
  var date = new Date();
  date.setTime(1000 * seconds);
  return date.toString();
}

function getChange(data, symbolCode) {
  for (var i = 0; i < data.length; i++) {
    if (data[i]["symbolCode"] === symbolCode) {
      return data[i]["change"];
    }
  }
}

function getPrice(data, symbolCode) {
  for (var i = 0; i < data.length; i++) {
    if (data[i]["symbolCode"] === symbolCode) {
      return data[i]["close"];
    }
  }
}

Vue.filter("gmtFormat", gmtFormat);
Vue.filter("getChange", getChange);
Vue.filter("getPrice", getPrice);

Vue.prototype.getChange = getChange;
Vue.prototype.gmtFormat = gmtFormat;
Vue.prototype.getPrice = getPrice;
Vue.prototype.$baseApi = "/api";

//
Vue.config.productionTip = false;
Vue.config.devtools = true;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
