export default {
  common: {
    error: "Lỗi hệ thống, vui lòng thử lại!",
    home: "Trang chủ",
    detail: "Chi tiết",
    market: "Chợ",
    trade: "Giao dịch",
    options: "Tùy chọn",
    wallets: "Ví",
    notMore: "Không còn bản ghi nào nữa",
    reconnect: "Đang kết nối lại",
    noData: "Không có nội dung vào lúc này",
    loading: "Đang tải...",
    liveChat: "Trò chuyện trực tiếp",
    help: "Giúp đỡ",
    lang: "Ngôn ngữ",
    notify: [
      "Hãy mở liên kết này trong ví của bạn!",
      "gửi thành công",
      "Bạn đã nhận được phiếu thưởng!",
      "Không thể vượt qua ủy quyền",
    ],
  },
  header: {
    voucher: "Nhận Voucher",
    connectWallet: "Đăng nhập",
    receive: "Nhận mô tả",
    reveiveInfo:
      "Bạn cần phải trả phí cho người khai thác để nhận được phiếu thưởng, vui lòng đảm bảo rằng ví của bạn có đủ ETH làm phí cho người khai thác",
    receiveBtn: "Nhận được",
    reward: "Bạn đã giành được phần thưởng",
  },
  share: {
    shareFriend: "Chia sẻ bạn bè",
    info: "Gửi liên kết lời mời của bạn, bạn bè tham gia nút thông qua liên kết của bạn và bạn sẽ nhận được phần thưởng mã thông báo hào phóng",
    myShareLink: "Liên kết chia sẻ của tôi",
    copy: "Sao chép",
  },
  ad: {
    plan: "KẾ HOẠCH TIẾT KIỆM:",
    regular: "TIẾT KIỆM THƯỜNG XUYÊN:",
  },
  output: {
    interest: "Quan tâm",
    desc: "Thu nhập lãi linh hoạt",
    output: "đầu ra",
    address: "Địa chỉ",
    quantity: "Số lượng",
  },
  order: {
    order: "Đặt hàng",
    amount: "Số lượng",
    available: "Có sẵn",
    all: "Tất cả",
    confirm: "Xác nhận",
    period: "Giai đoạn",
    requird: "Yêu cầu về số tiền",
    rate: "Tỷ giá(ETH)",
    additional: "Phần thưởng bổ sung",
    less: "Không ít hơn 1 USDC",
    participants: "Những người tham gia",
    total: "Tổng số tiền(usdc)",
  },
  chart: {
    loading: "đang tải",
    min1: "1 phút",
    min15: "15 phút",
    min5: "5 phút",
    min30: "30 phút",
    hour1: "1 giờ",
    hour4: "4 giờ",
    day1: "1 ngày",
    week1: "1 tuần",
    mon1: "1 tháng",
    more: "Hơn",
    price: "Giá",
    open: "Mở",
    amount: "Số lượng",
    high: "Cao",
    low: "Thấp",
    buy: "Mua",
    sell: "Bán",
    buyLong: "Mua/Long",
    sellShort: "Bán/Short",
    main: "Chủ yếu",
    side: "phụ",
    setting: "Cài đặt",
    history: "Vị trí",
  },
  futureOrder: {
    title: "Xác nhận đơn hàng",
    name: "Tên",
    direction: "Phương hướng",
    price: "Giá",
    info: "Thời gian giao hàng (Vuốt sang trái để nhận thêm lợi nhuận)",
    amount: "Số tiền(USDC)",
    available: "USDC có sẵn",
    fee: "Phí",
    second: "S",
    buy: "mua",
    sell: "bán",
    minAount: "Tối thiểu là ${minAmount}-${maxAmount}",
    startPrice: "Giá đặt hàng",
    expectedProfits: "Lợi nhuận dự kiến",
    finish: "Hoàn thành quyết toán đúng hạn",
    currentPrice: "Giá hiện tại",
    completeResult: "Kết quả hoàn thành",
    confirmOrder: "Xác nhận",
    continueBuy: "Tiếp tục",
  },
  futureHistory: {
    title: "lịch sử đơn hàng",
    direction: "Phương hướng",
    amount: "Số lượng",
    buyPrice: "Giá đặt hàng",
    OrderPrice: "Giá niêm yết",
    deliveryTime: "Kiểu",
    pl: "P/L",
    sellTime: "Thời gian hoàn thành",
  },
  market: {
    currency24h: "Tiền tệ24h",
    volume: "Âm lượng",
    liquidity: "Thanh khoản",
  },
  user: {
    title:
      "Xin chúc mừng những người dùng mới sau đây đã tham gia nhóm khai thác.",
    nation: "Quốc tịch của người dùng",
    address: "Địa chỉ chuỗi công khai",
    Hashrate: "Tỷ lệ băm của ví",
  },
  rule: {
    daily: "Hằng ngày",
    interest: "quan tâm",
    rule: "luật lệ",
    flexible: "Linh hoạt",
    payments: "Thanh toán lãi được thực hiện 4 giờ một lần, 6 lần một ngày.",
    safety: "An toàn tài sản",
    less: "Khoảng 80% tài sản dự trữ USDC là Kho bạc Hoa Kỳ ngắn hạn có kỳ hạn từ 3 tháng trở xuống.",
    economy:
      'Đây được coi là một trong những tài sản an toàn nhất trên thế giới và được hỗ trợ bởi "sự tin tưởng và chứng thực hoàn toàn" của chính phủ Hoa Kỳ, vốn được hỗ trợ bởi nền kinh tế lớn nhất thế giới.',
    fees: "Phí",
    additional: "Không có phí bổ sung để rút tiền lãi.",
  },
  report: {
    title: "Ví Web3 có hỗ trợ dapp",
    subTitle: [
      "Điện thoại di động",
      "Phần cứng",
      "Hợp đồng thông minh",
      "Khác",
    ],
  },
  help: {
    article: [
      {
        title: "Sở hữu dữ liệu của bạn",
        details: [
          "Tận dụng tất cả lợi ích của ví tiền điện tử phần mềm này bằng cách sử dụng chuỗi khối Ethereum để chỉ bạn mới có thể sở hữu dữ liệu của mình và lưu trữ cục bộ. Cho phép truy cập vào ví của bạn thông qua tiện ích mở rộng trình duyệt hoặc ứng dụng di động",
        ],
      },
      {
        title: "Lợi ích của WEB3.0 là gì",
        details: [
          "Web 3.0 được xây dựng dựa trên các khái niệm cốt lõi về phân quyền, tính mở và tiện ích lớn hơn cho người dùng.",
          "Trong một hệ thống phi tập trung, thông tin có thể được truy xuất dựa trên nội dung của nó, do đó nó có thể được lưu giữ ở nhiều vị trí cùng lúc, do đó làm cho nó trở nên phi tập trung. Điều này sẽ giải mã các bộ dữ liệu khổng lồ hiện đang được những gã khổng lồ internet nắm giữ, đồng thời làm cho chúng có thể mở rộng, an toàn và có thể truy cập được.",
        ],
      },
      {
        title: "Là gì",
        details: [
          "Các ứng dụng phi tập trung (dApps) là các chương trình chạy trên các nút của mạng blockchain hoặc mạng ngang hàng (P2P) thay vì dựa vào một máy tính tập trung duy nhất.",
          "Một số lợi ích của dApp là chúng hiệu quả hơn về mặt tài chính vì chúng có thể hoạt động mà không cần người trung gian để thu lợi nhuận từ các giao dịch. Người dùng chịu trách nhiệm về các tác động pháp lý và quy định đối với nội dung họ chia sẻ hoặc tiêu thụ.",
        ],
      },
      {
        title: "HỢP ĐỒNG THÔNG MINH",
        details: [
          "Các ứng dụng phi tập trung (dApps) là các chương trình chạy trên các nút của mạng blockchain hoặc mạng ngang hàng (P2P) thay vì dựa vào một máy tính tập trung duy nhất.",
          "Một số lợi ích của dApp là chúng hiệu quả hơn về mặt tài chính vì chúng có thể hoạt động mà không cần người trung gian để thu lợi nhuận từ các giao dịch. Người dùng chịu trách nhiệm về các tác động pháp lý và quy định đối với nội dung họ chia sẻ hoặc tiêu thụ.",
        ],
      },
    ],
    center: "Trung tâm trợ giúp",
    hope: "Hy vọng nó sẽ giúp bạn",
  },
  partner: {
    title1: "Báo cáo kiểm toán",
    detail1: "Chúng tôi có một báo cáo kiểm toán an toàn",
    title2: "Cộng sự",
    detail2: "Đối tác kinh doanh của chúng tôi",
  },
  trading: {
    titles: ["Tổng khối lượng giao dịch", "Lãi suất mở", "Tổng số người dùng"],
    currency: "Tiền tệ phổ biến",
    banners: ["Tiết kiệm thanh khoản để kiếm USDC", "lợi thế của chúng tôi"],
  },
  poolIndex: {
    tab1: "Dữ liệu nhóm",
    tab2: "Kế hoạch",
    tab3: "Thuế",
    tab4: "Tài khoản",
    transfer: "Chuyển USDC từ ví sang số dư",
    button: "Chuyển khoản",
  },
  liquidity: {
    interest: "Quan tâm",
    period: "Giai đoạn",
    amountRequire: "Yêu cầu về số tiền(USDC)",
    rate: "Tỷ giá(ETH)",
    additionalReward: "Phần thưởng bổ sung",
    days: "Ngày",
    subscription: "Cổ phần",
    redeem: "Chuộc lại",
    record: "Ghi",
    participants: "Những người tham gia",
    totalAmount: "Tổng số tiền(usdc)",
    interestRate: "Lãi suất",
    amount: "Số tiền(usdc)",
    collectionAmount: "Số tiền thu",
    reward: "Phần thưởng",
    addReward: "Phần thưởng bổ sung",
    endTime: "Thời gian kết thúc",
    status: "Trạng thái",
    stakingAmount: "Số tiền đặt cược",
    unCollected: "Số tiền chưa thu",
  },
  pool: {
    total: "Tổng số liệu cổ tức",
    participant: "Người tham gia",
    userRevenue: "Doanh thu của người dùng",
    poolData: "Dữ liệu nhóm",
  },
  message: {
    title: "Tin nhắn",
    ok: "ĐƯỢC RỒI",
  },
  lottery: {
    title: "Hộp mù bơm USDC",
    btn: "Ngẫu nhiên một",
    stake: "Cổ phần",
    connectWallet: "Kết nối ví",
    voucher: "Nhận Voucher",
    tab1: "Dữ liệu nhóm",
    tab2: "Kế hoạch",
    tab3: "Thuế",
    tab4: "Tài khoản",
    ok: "Được rồi",
    lotteryChance: "Chúc mừng bạn đã có cơ hội rút thăm trúng thưởng ETH!",
    lotteryBtn: "Bắt đầu xổ số",
    congratulations: "Chúc mừng",
    experienceUsdt1: "Chúc mừng! Bạn nhận được một",
    experienceUsdt2: "Quỹ dùng thử USDC. Tiền lãi hiện tại từ việc dùng thử là",
    experienceUsdt3: "ETH. ​​Thời gian hết hạn của bản dùng thử là",
  },
  my: {
    myAccount: "Tài khoản của tôi",
    totalOutput: "Tổng sản lượng",
    walletBalance: "Số dư trên ví",
    exchangeable: "Có thể trao đổi",
    exchange: "Trao đổi",
    withdraw: "Rút",
    record: "Ghi",
  },
  exchange: {
    redeem: "Chuyển đổi tất cả",
    exchange: "Trao đổi",
    convert: "Chuyển đổi tiền Eth để lấy USDC",
  },
  withdraw: {
    total: "Tổng số dư",
    confirm: "Xác nhận",
    tip: "Khoản tiền bạn rút sẽ được gửi đến địa chỉ ví USDC của bạn trong vòng 24 giờ tới",
  },
  record: {
    time: "Thời gian",
    quantity: "Số lượng",
    status: "Trạng thái",
    output: "đầu ra",
    exchange: "Trao đổi",
    withdraw: "Rút",
    interest: "Quan tâm",
    rebate: "Hạ giá",
  },
  loan: {
    title: "Số tiền vay",
    dueDate: "Ngày đáo hạn",
    tips: ["Khoản vay với", "tài liệu đơn giản"],
    form: "Mẫu đơn xin cho vay",
    download: "Tải xuống",
    print: "In và điền vào mẫu",
    upload: "Chụp ảnh và tải mẫu lên",
    btn: "Tải lên",
    file: "Tải tập tin",
    cancel: "Hủy bỏ",
    uFile: "Cập nhật dử liệu",
    tip: "bấm vào để tải lên",
    doc1: "HỢP ĐỒNG VAY CÁ NHÂN 1.doc",
    doc2: "HỢP ĐỒNG VAY CÁ NHÂN 2.doc",
    file1: "PERSONAL_LOAN_AGREEMENT-1.docx",
    file2: "PERSONAL_LOAN_AGREEMENT-2.docx",
  },
  nav: {
    home: "Trang chủ",
    lang: "Ngôn ngữ",
    loan: "Khoản vay",
    paper: "Giấy",
    pool: "Dữ liệu nhóm",
    trading: "Thương mại",
  },
  whitepaper: {
    title: "Sách trắng USDC",
    view: "Xem",
    eth: "Sách trắng Ethereum",
  },
  nft: {
    vol: "Khối lượng giao dịch",
    amount: "số tiền giao dịch",
    qty: "số lượng Nft",
    users: "Người dùng lợi nhuận",
    quantity: "Số lượng",
    totalVol: "Tổng khối lượng giao dịch",
    lastestTrans: "Giá giao dịch mới nhất",
    collQuantity: "Số lượng thu thập",
    showMore: "Cho xem nhiều hơn",
    pd: "Chi tiết dự án",
    sellPrice: "Gia ban",
    royalty: "Tiền bản quyền",
    royaltyTip:
      "Tất cả tiền bản quyền từ giao dịch này sẽ được quyên góp cho tổ chức từ thiện",
    buy: "Mua",
    basic: "Thông tin cơ bản",
    contract: "Địa chỉ hợp đồng",
    network: "Mạng",
    attribute: "Thuộc tính",
    trait: "Có đặc điểm này",
    records: "Hồ sơ",
    balance: "Sự cân bằng",
    sellNow: "Bán ngay bây giờ",
    cancel: "Hủy bỏ",
    buyNow: "Mua ngay",
    pay: "Được trả tiền",
    transPass: "Mật khẩu giao dịch",
    transTip:
      "Lưu ý: Hãy nhớ mật khẩu giao dịch của bạn. Nếu bạn quên mật khẩu giao dịch, vui lòng liên hệ với bộ phận dịch vụ khách hàng trực tuyến của nền tảng.",
    newPass: "Mật khẩu mới",
    rePass: "Xác nhận mật khẩu",
    confirm: "Xác nhận",
    purchaseTime: "Thời gian mua hàng",
    purchaseAmount: "Số tiền mua",
    price: "Giá",
    priceTip: "Vui lòng nhập giá bán",
    sell: "Bán",
    digitTip: "Vui lòng nhập 6 chữ số",
  },
  info: {
    1: [
      "USDC là đồng tiền được các nhà giao dịch tận dụng để kiếm lãi. Có nhiều cách để làm điều này và đó là một trong những tính năng độc đáo của tiền điện tử, vì các ngân hàng truyền thống cung cấp rất ít lợi nhuận. Hiểu cách sử dụng mã thông báo USDC rất có giá trị đối với những người giao dịch tiền điện tử.",
      "Mục lục",
      "1 Đồng usd (USDC) là gì?",
      "2 Lãi suất USDC hoạt động như thế nào?",
      "3 Làm cách nào để kiếm lãi trên USDC?",
      "4 Các cách khác nhau để kiếm lợi nhuận trên USDC là gì?",
      "5 Thu lãi bằng USDC có đáng không?",
      "6 Câu hỏi thường gặp: Câu hỏi thường gặp",
      "Đồng USD (USDC) là gì?",
      "USD Coin, hay còn gọi là “USDC”, là một loại tiền điện tử được gọi là stablecoin. Circle và Coinbase phát hành nó, hai trong số các tổ chức tài chính được quản lý lớn nhất ở Hoa Kỳ, được hỗ trợ hoàn toàn bằng tài sản dự trữ. Giá của một USDC được chốt bằng một đô la. Nói cách khác, nó có thể được đổi 1 đổi 1 thành đồng đô la Mỹ.",
      "Điều này cho phép thực hiện các giao dịch được chốt bằng USD trong ví tiền điện tử. USD Coin là mã thông báo Ethereum ERC-20 được cung cấp bởi mạng Ethereum, nghĩa là nó có thể được lưu trữ trong bất kỳ ví nào có thể chấp nhận Ethereum. Điều này làm cho nó cực kỳ linh hoạt và có thể được sử dụng bởi hầu hết mọi người trong không gian tiền điện tử.",
      "Lãi suất USDC hoạt động như thế nào?",
      "Stablecoin, là các token có giá trị được gắn với giá trị của một tài sản khác, rất lý tưởng để kiếm lãi. Trong trường hợp USDC, nó có giá trị một đô la. Tiền lãi kiếm được từ những đồng tiền này có xu hướng cao hơn nhiều so với các loại tiền điện tử khác vì nó có thể được định lượng dễ dàng hơn nhiều. Khi tài sản ổn định, việc đưa ra phần thưởng sẽ dễ dàng hơn vì nó không nên biến động quá nhiều. Bằng cách cho phép mọi người vay USDC, các công ty và cá nhân có thể vay tiền thông qua blockchain để đầu tư, vì lý do cá nhân hoặc giao dịch có đòn bẩy. Họ đồng ý hoàn trả khoản vay kèm theo lãi suất, giống như khoản vay truyền thống tại ngân hàng. Nói cách khác, nếu bạn vay 10.000 USDC thì cũng tương tự như vay 10.000 USD.",
      "Lãi suất tiền điện tử so với Stablecoin",
      "Bạn có thể kiếm lãi từ cả stablecoin và tiền điện tử. Tuy nhiên, chúng khá khác nhau và hồ sơ rủi ro mà bạn sẽ xử lý sẽ phản ánh điều này. Vì tiền điện tử dễ biến động hơn nhiều so với stablecoin nên các sàn giao dịch ít sẵn sàng cho vay chúng hơn. Một loại tiền điện tử có thể mất 30% giá trị chỉ trong một ngày, nhưng stablecoin hầu như không di chuyển.",
      "Do tính biến động cực độ của tiền điện tử, các sàn giao dịch cung cấp cho những người đặt cược tiền điện tử trên sàn giao dịch của họ mức giá thấp hơn đối với loại tiền này. Rốt cuộc, họ có thể gặp khó khăn khi phải trả những khoản tiền lãi tăng thêm. Mặt khác, những người đặt cọc tiền điện tử của họ có thể nhận thấy mình nhận được một chút lãi suất, nhưng đồng thời lại mất một lượng lớn tiền gốc. Luôn luôn có rủi ro.",
      'Vì các token như USDC ít biến động hơn nên stablecoin là cách an toàn hơn để lưu trữ giá trị bằng tiền điện tử. Do đó, các sàn giao dịch cũng như blockchain sẵn sàng cung cấp cho người dùng mức lãi suất cao hơn. USDC APR có xu hướng mạnh, chủ yếu là vì nó "ổn định".',
      "Tại sao bạn nên kiếm lãi trên USDC",
      "Có nhiều lý do khiến bạn muốn kiếm tiền lãi từ USDC và những lợi ích bao gồm những điều sau và hơn thế nữa:",
      "Thay thế tiết kiệm:",
      "Một số người sẽ kiếm lãi từ USDC để thay thế tài khoản tiết kiệm. Các tài khoản tiết kiệm có rất ít lãi suất trong vài năm qua và tiền điện tử đã gấp rút lấp đầy khoảng trống đó.",
      "Năng suất cao hơn:",
      "Lợi suất kiếm được khi đặt cược tiền điện tử, bao gồm USDC, thường cao hơn nhiều so với ngân hàng truyền thống.",
      "Kiếm tiền trong khi chờ đợi:",
      "Thị trường đôi khi có thể nguy hiểm và không ổn định. Vì điều này, một số nhà giao dịch sẽ đặt cọc USDC trong khi đứng ngoài thị trường. Đây là một cách tuyệt vời để tiếp tục phát triển tài khoản của bạn.",
      "Làm cách nào để kiếm lãi trên USDC?",
      "Để hưởng lợi từ USDC, trước tiên bạn phải sở hữu nó. Việc lưu trữ USDC rất đơn giản và có thể được thực hiện trên hầu hết các sàn giao dịch. Bạn cần phải đăng ký với nhiều sàn giao dịch. Chúng tôi khuyên bạn nên chuyển tiền tới sàn giao dịch của mình. Khi tiền chuyển khoản ngân hàng của bạn đến sàn giao dịch, trước tiên bạn cần mua ETH trên sàn giao dịch mà bạn đã đăng ký, sau đó gửi ETH đến sàn giao dịch của bạn. Trong ví WEB3, bước tiếp theo là chuyển đổi ETH nhận được trong ví WEB3 của bạn thành USDC. Việc này rất đơn giản và có thể hoàn thành trong vài phút.",
      "Các cách khác nhau để kiếm lợi nhuận trên USDC là gì?",
      "Có nhiều cách và địa điểm khác nhau để bạn có thể kiếm lãi từ USDC của mình. Tuy nhiên, bạn cần biết sự khác biệt giữa từng loại nền tảng cũng như ưu và nhược điểm của chúng.",
      "Kiếm lợi nhuận trên USDC thông qua sàn giao dịch",
      "Kiếm lợi nhuận thông qua trao đổi có lẽ là quy trình đơn giản nhất. Hầu hết các sàn giao dịch đều yêu cầu một loạt các bước tương tự, bao gồm tạo tài khoản, gửi USDC và chọn sản phẩm kiếm lợi nhuận ưa thích của bạn. Điều này có thể có hoặc không liên quan đến việc khóa USDC trong một thời gian nhất định. Đây sẽ là một vấn đề nếu bạn cần thanh khoản.",
      "Ưu điểm đáng kể nhất của việc sử dụng sàn giao dịch tập trung là tính đơn giản, vì nhiều khả năng bạn sẽ không giữ tiền điện tử của mình hoặc ít nhất là cấp vốn cho giao dịch tiền điện tử của mình trên nền tảng đó.",
      "Ưu điểm:",
      "Nhược điểm:",
      "Dễ dàng và thuận tiện.",
      "Có thể hoặc không bị buộc phải khóa UsDc trong một thời gian cụ thể. người dùng.",
      "Nó có thể được thực hiện trên cùng một nền tảng.",
      "Có thể nhận được mức giá thấp hơn.",
      "Hầu hết các sàn giao dịch đều được vốn hóa tốt.",
      "Sản phẩm có sẵn có thể bị hạn chế.",
      "Web3 là một trong những công ty dẫn đầu trong lĩnh vực đặt cược tiền điện tử này.",
      "Kiếm lợi nhuận trên USDC với DeFi",
      "Một trong những khả năng thú vị nhất của tiền điện tử là nền tảng DeFi cho phép người dùng truy cập các dịch vụ tài chính thiết yếu mà không cần trung gian. Người dùng có thể gửi tiền điện tử, trong trường hợp này là USDC, vào một giao thức cho vay phi tập trung để kiếm lãi.",
      "Lĩnh vực tài chính phi tập trung này cho phép người dùng vay, cho vay và đầu tư vào các sản phẩm tài chính mới. Tất cả điều này có thể được thực hiện bằng cách sử dụng hợp đồng thông minh và công nghệ blockchain.",
      "Ưu điểm:",
      "Nhược điểm:",
      "Hợp đồng thông minh cung cấp sự dễ sử dụng.",
      "Giao thức DeFi không khắc phục được lỗi của người dùng.",
      "Không có trung gian.",
      "Khả năng mở rộng có thể là một vấn đề với một số giao thức.",
      "Các sản phẩm khác có sẵn.",
      "Thanh khoản có thể là một vấn đề với các giao thức nhỏ hơn.",
      "Ví dụ về các giao thức tài chính phi tập trung bao gồm Uniswap và Bancor.",
      "Thu lãi bằng USDC có đáng không?",
      "Kiếm lãi từ USDC là một cách tuyệt vời để kiếm lãi từ tài sản tiền điện tử của bạn. Hãy nhớ rằng lãi suất cao hơn nhiều so với các tài khoản tiết kiệm thông thường trong thế giới thực, vì vậy bạn có thể kiếm được lợi nhuận cao hơn.",
      "Kiếm lãi từ USDC có lợi cho hầu hết các nhà giao dịch.",
      "Có nhiều lý do bạn có thể chọn sử dụng USDC để bắt đầu kiếm lãi. Điều rõ ràng nhất là nó vượt trội hơn nhiều so với một ngân hàng truyền thống tiêu chuẩn về mặt lãi suất. Ngoài ra, USDC là một cách tuyệt vời để kiếm lãi từ những tài sản có thể mất giá trị trong một đợt bán tháo tiền điện tử nói chung. Ví dụ: bạn có thể rút Bitcoin của mình và sau đó chuyển đổi nó thành USDC, nơi bạn có thể kiếm lãi trong khi chờ thị trường quay đầu.",
      "Hãy nhớ rằng không có gì là an toàn 100%, nhưng bạn nên được bảo vệ miễn là bạn sử dụng dịch vụ trao đổi hoặc cho vay có uy tín. Hãy nhớ rằng đây có thể là một cách tuyệt vời để đa dạng hóa một phần danh mục đầu tư tổng thể của bạn, vì việc kiếm lãi từ USDC có thể được coi rất giống một trái phiếu có lãi suất cao.",
      "Hầu hết các nhà giao dịch sẽ được hưởng lợi bằng cách nắm giữ USDC và tất nhiên là cố gắng kiếm lời trong những thời điểm thị trường đang gặp khó khăn để giải quyết. Bằng cách có những tài sản an toàn này trong danh mục đầu tư của bạn, nó không chỉ mang lại cho bạn sự an toàn mà còn linh hoạt vì chúng có thể được chuyển đổi thành các đồng tiền khác hoặc được sử dụng làm mạng lưới an toàn. Hầu hết các nhà giao dịch chuyên nghiệp đều có ít nhất một phần số tiền này vào nhiều thời điểm khác nhau.",
      "Câu hỏi thường gặp: Câu hỏi thường gặp",
      "Đồng USDC có trả lãi không?",
      "Bản thân đồng tiền này không trả lãi, nhưng có rất nhiều sàn giao dịch và người cho vay thực hiện việc này. Đây là một trong những loại tiền phổ biến nhất để kiếm lãi, vì vậy bạn có rất nhiều lựa chọn.",
      "USDC có trả lãi hàng tháng không?",
      "Nó phụ thuộc vào nơi bạn đang cho vay nó. Thời hạn gửi tiền có thể dao động từ rất ngắn hạn đến một năm hoặc hơn.",
      "Bạn có thể kiếm tiền khi mua USDC không?",
      "Không nhất thiết, mặc dù kiếm lãi là một cách kiếm tiền. USDC được gắn với đồng đô la Mỹ, vì vậy về cơ bản đây là giao dịch một đổi một.",
      "Tại sao lãi suất của USDC lại cao đến vậy?",
      "Lãi suất USDC cao do tình hình cung cầu. Điều này có xu hướng tăng và giảm trong các điều kiện thị trường khác nhau, đặc biệt là loại thị trường được gọi là “mùa đông tiền điện tử”. Nhu cầu vay thường vượt quá nguồn cung USDC có thể cho vay. Lãi suất sẽ phản ánh điều này, tăng giảm theo cung cầu.",
      "Bạn có thể mất tiền trên USDC không?",
      "Về lý thuyết, bạn có thể làm được, giả sử rằng tỷ giá hối đoái với đồng đô la Mỹ đã bị phá vỡ. Cho đến nay, đây là một trong những đồng tiền an toàn nhất hiện có trong thế giới tiền điện tử.",
      "Ý nghĩa của USDC là gì?",
      "USDC có nhiều cách sử dụng, cách rõ ràng nhất là cách chuyển đô la Mỹ trên blockchain. Tuy nhiên, kiếm lãi là một điểm quan trọng khi sử dụng nó vì bạn có thể gửi nó vào một sàn giao dịch, dịch vụ cho vay hoặc không gian DeFi.",
    ],
    2: [
      "Mục lục",
      "1. Ví Web3 là gì?",
      "1.1. Web3 là gì?",
      "2. Tại sao bạn cần ví Web3?",
      "3. Các lựa chọn thay thế ví Web3",
      "3.1. MetaMask",
      "3.2. Ví Coinbase",
      "3.3. Argentina",
      "3.4. Ví Trust",
      "3.5. cầu vồng",
      "4. Ví Web3 là gì?-Tóm tắt",
      "Khi sự quan tâm đến Web3 ngày càng tăng, sự tham gia vào các hệ sinh thái khác nhau cũng tăng lên đáng kể. Hơn nữa, nếu bạn không quen với lĩnh vực này, có vẻ như rào cản gia nhập rất cao và khó vượt qua. Tuy nhiên, với sự quan tâm ngày càng tăng đối với tiền điện tử và phát triển Web3, những rào cản này dần biến mất khi ngành này trở nên thân thiện với người dùng hơn. Cùng với việc trở nên dễ tiếp cận hơn, không gian tiền điện tử cũng phát triển khi sự cạnh tranh ngày càng tăng, có nghĩa là các dApps (ứng dụng phi tập trung), mã thông báo, NFT và ví Web3 mới liên tục được phát triển. Ví rất quan trọng để tiếp cận tiền điện tử vì chúng thực hiện một số chức năng thiết yếu trong không gian Web3. Do đó, trong bài viết này, chúng ta sẽ đi sâu hơn vào không gian tiền điện tử và trả lời câu hỏi “ví Web3 là gì?”.",
      "Để hiểu rõ hơn về ví Web3 là gì, chúng tôi cũng sẽ nghiên cứu sâu hơn về một số lựa chọn thay thế phổ biến nhất trên thị trường. Hơn nữa, nếu bạn quan tâm đến việc phát triển Web3 và muốn tạo ví tiền điện tử của riêng mình, chúng tôi sẽ đưa bạn đi đúng hướng, một con đường bắt đầu từ đây tại WEB3.0.",
      "Với WEB3, bạn có thể phát triển ví Web3 của riêng mình một cách nhanh chóng và hiệu quả\n vì bạn có quyền truy cập vào cơ sở hạ tầng phụ trợ cơ bản của hệ điều hành. Web3.0 là hệ điều hành số một để phát triển Web3 và cùng với cơ sở hạ tầng phụ trợ, người dùng còn có quyền truy cập vào hộp công cụ mở rộng. Trong số các công cụ, bạn sẽ tìm thấy các giải pháp như Nút tốc độ Web3.0, SDK Web3.0, API giá, hỗ trợ riêng cho IPFS, v.v.",
      "Vì vậy, nếu bạn đang muốn trở thành nhà phát triển và tiết kiệm blockchain, bước đầu tiên là đăng ký Web3.0. Làm như vậy chỉ mất vài phút và đăng ký là hoàn tất.",
      "Ví Web3 là gì?",
      "Nếu bạn đang nghĩ đến việc tham gia vào lĩnh vực tiền điện tử, bước đầu tiên trong hành trình của bạn là có được ví Web3. Ví Web3 rất cần thiết để truy cập vào không gian Web3, DeFi và tiền điện tử. Bạn có thể đã gặp phải những từ 〝Web3” hoặc 〝ví tiền điện tử”. Nếu bạn là người mới\n vào không gian, trước tiên chúng ta hãy trả lời câu hỏi\n〝ví Web3 là gì?”.",
      "Trước hết, ví Web3 về cơ bản là ví kỹ thuật số. Như vậy, họ có khả năng lưu trữ tài sản kỹ thuật số. Điều này bao gồm mọi thứ từ mã thông báo có thể thay thế đến không thể thay thế (NFT). Thứ hai, ví Web3 cũng mở ra cánh cửa đến lĩnh vực tiền điện tử, cho phép bạn tương tác với các dApp trên nhiều chuỗi khối khác nhau. Đổi lại, ví giúp bạn truy cập vào hệ sinh thái dApps rộng lớn.",
      "Ví tiền điện tử thường có đặc điểm không giám sát, có nghĩa là bạn, với tư cách là chủ sở hữu ví, có thể lưu trữ tài sản kỹ thuật số mà không cần qua trung gian hoặc người trung gian. Điều này có nghĩa là bạn với tư cách là người dùng vẫn có toàn quyền kiểm soát tất cả tài sản của mình mà không ai\n người khác có quyền truy cập vào mã thông báo của bạn. Tuy nhiên, với quyền truy cập độc quyền, mọi trách nhiệm thuộc về bạn, có nghĩa là việc giữ khóa riêng cho chính mình là điều cần thiết.",
      "Cùng với khả năng lưu trữ tài sản kỹ thuật số, ví thường cung cấp các chức năng bổ sung. Chẳng hạn, điều này cho phép sử dụng ví Web3 để gửi và trao đổi mã thông báo. Do đó, ví tiền điện tử có thể được sử dụng để quản lý hoàn toàn tài sản của bạn, bao gồm cả cách nhận được\n token bổ sung.",
      "Trên thị trường có rất nhiều loại ví khác nhau với những thế mạnh riêng. Một số ví phổ biến nhất là MetaMask, ví Coinbase, ví DeFi TrustWallet, Argent,\n Atoken, Hyperpay, Bitkeep, v.v. Tuy nhiên, chúng ta sẽ tìm hiểu sâu hơn về các lựa chọn thay thế này trong phần khác sau này. Hơn nữa, một chủ đề bổ sung đáng xem xét kỹ hơn là WalletConnect, vì nó được kết nối chặt chẽ với ví Web3.",
      "Web3 là gì?",
      "Với sự xuất hiện của ngành công nghiệp blockchain, rất có thể bạn đã nghe nói đến “Web3”. Tuy nhiên, đối với những người chưa quen, đây có thể là một thuật ngữ xa lạ và có thể hơi khó hiểu. Do đó, trong phần này, chúng tôi sẽ dành thời gian giải thích và tóm tắt Web3 là gì.",
      "Nói chung, Web3 đề cập đến “thế hệ mới nhất” hoặc “giai đoạn” của Internet. Như bạn có thể đoán, các thế hệ trước là Web1 và Web2, các giai đoạn mà bạn quen thuộc hơn. Ba thế hệ internet không bắt đầu ở một thời điểm cụ thể và\n không phải do một thực thể duy nhất khởi xướng để cách mạng hóa Internet. Tuy nhiên, mỗi giai đoạn có những đặc điểm riêng trong đó Web1 là tĩnh, Web2 động và Web3 phi tập trung.",
      "Với việc phân cấp là một khái niệm trung tâm trong giai đoạn mới nhất của Internet, nó\n chiếm ưu thế bởi dữ liệu phi tập trung. Không giống như Web2, không có thực thể tập trung duy nhất\n  dữ liệu riêng đó; thay vào đó, nó được phân phối và chia sẻ. Hơn nữa, Web3 cuối cùng cũng\n giải quyết vấn đề với các công ty sở hữu lượng lớn thông tin cá nhân với tư cách là người dùng\n     kiểm soát dữ liệu của chính họ.",
      "Trong hệ sinh thái Web3, chúng tôi cũng tìm thấy một thành phần thiết yếu khác, đó là dApps\n (ứng dụng phi tập trung). Đây là những ứng dụng phi tập trung thường\n dựa trên blockchain và hệ sinh thái dApps lớn nhất hiện được lưu trữ trên chuỗi khối Ethereum. Với khía cạnh phân cấp của dApps, có thể phát triển các ứng dụng mạnh mẽ giúp loại bỏ các vấn đề khác nhau đi kèm với việc tập trung hóa, bao gồm cả một điểm lỗi duy nhất.",
      "Tuy nhiên, nếu bạn muốn tìm hiểu thêm về giai đoạn mới nhất của Internet, chúng tôi khuyên bạn nên đọc bài viết giải thích chi tiết hơn về Web3 từ trang web.",
      "Tại sao bạn cần ví Web3?",
      "Trong phần “Ví Web3 là gì?”, chúng tôi đã trình bày ngắn gọn lý do tại sao một người nào đó\n sẽ cần một chiếc ví như vậy. Tuy nhiên, trong phần này của bài viết, chúng tôi sẽ giải thích chi tiết về những điểm này. Vậy tại sao bạn cần ví Web3?",
      "Trường hợp sử dụng đầu tiên của ví Web3 là khả năng quản lý hoàn toàn tài sản tiền điện tử của bạn. ĐẾN\n minh họa điều này, chúng ta sẽ xem xét kỹ hơn về MetaMask, ví tiền điện tử lớn nhất trên\n  chợ. Nếu bạn có tài khoản MetaMask, bạn có thể đã quen với cách hoạt động của ví; tuy nhiên, đây là giao diện trông như thế nào:",
      'Như bạn có thể thấy, bạn có hai tab khác nhau, một tab dành cho "Tài sản" và một tab dành cho "Hoạt động". Các\n  tab hoạt động chỉ hiển thị lịch sử giao dịch của bạn mà bạn có thể khám phá một cách độc lập. Hơn nữa, trong tab tài sản, bạn có thể xem tất cả tài sản của mình. Ngoài ra, còn có ba nút hành động: mua, gửi, trao đổi. Các nút này tự giải thích và chúng tôi sẽ không đề cập đến chúng chi tiết hơn nữa.',
      "Tuy nhiên, điều này cho thấy rằng với tư cách là một ví tiền điện tử, bạn có thể quản lý hoàn toàn tất cả tài sản của mình trực tiếp thông qua MetaMask. Hơn nữa, đây không phải là trường hợp duy nhất vì hầu hết các ví khác đều cho phép bạn quản lý tài sản của mình theo cách tương tự.",
      "Lý do thứ hai khiến bạn cần ví Web3 là khả năng truy cập. Ví tiền điện tử\n là cổng vào các blockchain khác nhau. Do đó, ví Web3 cho phép người dùng đạt được\n dễ dàng truy cập vào dApps vì tài sản tiền điện tử là cần thiết để tương tác với hầu hết các ứng dụng. Như vậy, người dùng có thể tự xác thực bằng ví của mình, đảm bảo rằng họ có\n phương tiện để thanh toán cho một giao dịch.",
      "Nếu bạn quan tâm hơn đến việc xác thực người dùng, hãy xem các bài viết đề cập đến cách xác thực bằng MetaMask và cách kết nối người dùng với WalletConnect.",
      "Các lựa chọn thay thế ví Web3",
      "Trước đó, chúng tôi đã đề cập rằng số lượng ví Web3 đang tăng lên khi có nhiều người tham gia vào không gian tiền điện tử hơn. Đây là hậu quả của một vấn đề nan giải đơn giản là “cung và cầu” khi các doanh nghiệp nhìn thấy cơ hội phát triển ở một thị trường mới nổi. Tuy nhiên, điều này có thể khiến người dùng bối rối khi cố gắng lựa chọn trong số vô số lựa chọn thay thế.",
      "Vì vậy, chúng tôi sẽ nêu bật năm ví thay thế mà bạn có thể chọn. Hầu hết trong số họ\n phục vụ cùng một mục đích; tuy nhiên, chúng sẽ khác nhau đôi chút và chúng độc đáo trong\n cách riêng. Năm ví Web3 là:",
      "MetaMask",
      "Ví Coinbase",
      "Argentina",
      "Ví Trust",
      "cầu vồng",
      "Vì vậy, không dài dòng nữa, hãy bắt đầu bằng cách xem xét kỹ hơn về MetaMask!",
      "MetaMask",
      "Ví Web3 đầu tiên trong danh sách rút gọn của chúng tôi là MetaMask và đây là một trong những ví nổi tiếng nhất trên thị trường, với hơn 21 triệu người dùng trên toàn thế giới. Ví có sẵn ở hai dạng riêng biệt: ứng dụng di động hoặc tiện ích mở rộng trình duyệt. Trước đây chúng tôi đã đề cập\n MetaMask khi cố gắng nêu bật lý do tại sao ai đó cần ví tiền điện tử. Chúng tôi đã đưa ra quan điểm rằng có thể quản lý hoàn toàn tài sản tiền điện tử thông qua MetaMask trong\n một cách phi tập trung.",
      "Tuy nhiên, chúng tôi không đề cập rằng MetaMask là cửa ngõ vào hệ sinh thái Ethereum của dApps. Hơn nữa, Ethereum là blockchain phát triển quan trọng nhất, có nghĩa là\n rằng có hàng nghìn ứng dụng có sẵn cho người dùng MetaMask. Vì vậy, như\n Ethereum tiếp tục phát triển, việc có ví MetaMask sẽ mang lại lợi ích rất lớn. Để biết hướng dẫn chi tiết hơn về MetaMask, hãy xem bài viết giải thích về MetaMask của chúng tôi.",
      "Ví Coinbase",
      "Nếu bạn thể hiện sự quan tâm đến tiền điện tử thì rất có thể bạn đã nghe nói đến Coinbase. Coinbase là một trong những nền tảng tiền điện tử lớn nhất trên toàn cầu và cùng với nền tảng thân thiện với người dùng, Coinbase hiện còn cung cấp thêm ví Web3 dưới dạng Ví Coinbase.",
      "Ví Coinbase là ví Web3 không giám sát, độc lập với nền tảng Coinbase tập trung. Do đó, điều này cung cấp cho người dùng cách trải nghiệm thế giới dApps và tiền điện tử độc lập với sự kiểm soát trung tâm của Coinbase. Ngoài ra, một tính năng độc đáo của ví Coinbase là khả năng gửi và nhận tài sản tiền điện tử dựa trên tên người dùng. Bạn chỉ có thể gửi tài sản dựa trên ví\n Địa chỉ. Đây là những chuỗi từ và số dài và khó, vì vậy bạn sẽ phải sao chép và dán khi gửi và nhận tài sản tiền điện tử đến địa chỉ mà bạn chỉ định.",
      "Hơn nữa, nếu bạn muốn tìm hiểu thêm về cách gửi mã thông báo, bạn có thể xem bài viết này\n về cách gửi mã thông báo ERC-20. Điều này nhấn mạnh việc gửi mã thông báo ERC-20 dễ dàng như thế nào khi làm việc với Web3.0.",
      "Ví thứ ba là Argent và đây là một bổ sung tương đối mới cho bộ Web3 phong phú.\n ví trên thị trường. Argent phục vụ người dùng Ethereum vì nó chỉ tương thích với\n Mã thông báo Ethereum. Hơn nữa, ví tập trung vào việc cung cấp trải nghiệm thân thiện với người dùng khi sử dụng dApps và DeFi (tài chính phi tập trung). Họ đạt được điều này bằng cách tích hợp các dApp và giao thức vào dApp của Argent. Do đó, có thể vay và cho mượn tài sản trực tiếp thông qua ứng dụng của họ, điều này cho thấy có thể tránh sử dụng trình duyệt trong ứng dụng.",
      "Nếu bạn muốn tìm hiểu thêm về DeFi, chúng tôi khuyên bạn nên xem phần “DeFi là gì?” bài viết từ WEB3, bạn cũng có thể xem kỹ hơn các khóa học blockchain DeFi 101 và DeFi 201 nếu bạn muốn phát triển kỹ năng DeFi của mình.",
      "Tuy nhiên, một trong những nhược điểm lớn của Argent là ví này chỉ phục vụ cho mạng Ethereum. Như vậy, chẳng hạn, không thể lưu trữ các mã thông báo\n có nguồn gốc từ các chuỗi khác như chuỗi khối Bitcoin.",
      "Ví Trust",
      "Lựa chọn thay thế thứ tư là Trust Wallet và không giống như Argent, ví này có tiềm năng lưu trữ bất kỳ loại token nào. Ví này được gọi là blockchain bất khả tri, có nghĩa là nó hỗ trợ tài sản từ nhiều chuỗi khác nhau. Hơn nữa, Trust Wallet đã có chỗ đứng vững chắc trên thị trường khi ví này có hơn mười triệu người dùng trên toàn thế giới. Vì Trust Wallet hỗ trợ vô số tài sản và chuỗi khối, nên ví này trở thành một sự thay thế hợp lý để tận dụng mọi thứ mà web phi tập trung cung cấp.",
      "cầu vồng",
      "Tùy chọn cuối cùng là Rainbow và đây là ví không giám sát hỗ trợ mạng Ethereum. Hơn nữa, Rainbow có hỗ trợ riêng cho dApps của mạng Ethereum, giống như Argent. Một ví dụ ở đây là Uniswap V2, được sử dụng để thực hiện các giao dịch hoán đổi trong ứng dụng. Một chức năng thú vị khác của ví Rainbow là hỗ trợ NFT. Thông qua đó, ví có thể hiển thị gọn gàng tất cả tài sản kỹ thuật số của bạn.",
      "Đây chỉ là năm trong số nhiều lựa chọn thay thế có sẵn và như bạn có thể đã nhận thấy từ\n những mô tả ngắn gọn, chúng đều giống nhau và khác nhau. Vì vậy, việc tìm một chiếc ví phù hợp nhất với nhu cầu cụ thể của bạn là tùy thuộc vào bạn.",
      "Ví Web3 là gì? - Bản tóm tắt",
      "Nếu bạn đang dự định bước vào thế giới tiền điện tử thì điều đầu tiên bạn cần là một\n Ví Web3. Ví tiền điện tử cho phép bạn quản lý tất cả tài sản kỹ thuật số của mình trong khi\n đồng thời cung cấp quyền truy cập vào hệ sinh thái phi tập trung của dApps. Hơn nữa, tất cả điều này đều có thể thực hiện được trong khi vẫn có toàn quyền kiểm soát tất cả tài sản dưới dạng phân cấp\n đặc trưng của Web3 về bản chất. Vì vậy, không cần trung gian để thực hiện\n các hoạt động như giao dịch, cho vay và mượn tài sản.",
      "Với sự quan tâm ngày càng tăng đối với web phi tập trung, chúng tôi đã thấy số lượng các lựa chọn thay thế ví khác nhau tăng lên đáng kể. Tuy nhiên, năm trong số các lựa chọn phổ biến nhất là\n MetaMask, Ví Coinbase, Argent, Ví Trust và Rainbow. Mặc dù những điều này\n các lựa chọn thay thế có nhiều điểm tương đồng, một số trong số chúng phù hợp hơn những lựa chọn khác, tùy thuộc vào nhu cầu và tình huống của bạn.",
      "Hơn nữa, với sức mạnh của WEB3, bạn có thể dễ dàng và nhanh chóng phát triển ví của riêng mình với các tính năng cụ thể. Điều này có thể thực hiện được vì người dùng Moralis có thể tận dụng\n cơ sở hạ tầng phụ trợ cơ bản của hệ điều hành, giúp tạo ra một ví Web3 hoạt động đầy đủ chỉ trong vài phút.",
      "Tuy nhiên, giới hạn của Moralis không dừng lại ở đó. Trên thực tế, bạn có thể rút ngắn đáng kể thời gian phát triển của tất cả các dự án blockchain trong tương lai. Nếu bạn vẫn cần thuyết phục, hãy xem các bài viết từ blog cho thấy việc tạo Ethereum dApps, phát triển thị trường NFT của riêng bạn hoặc tạo mã thông báo BSC dễ dàng như thế nào trong năm bước.",
      "Vì vậy, nếu bạn có tham vọng nâng cấp trò chơi phát triển Web3 của mình thì hãy đăng ký WEB3 ngay. Làm như vậy sẽ cung cấp quyền truy cập vào cơ sở hạ tầng phụ trợ và các công cụ Moralis!",
    ],
    3: [
      "Web3 để quảng bá Web3\n Khái niệm và cung cấp học bổng\n tại UEL, Việt Nam...",
      'Web3 vui mừng thông báo kết thúc thành công của sự kiện mới nhất dành riêng cho sinh viên, "Gate Web3 - Trao quyền cho GenZ trong Web3". Cái này\n sự kiện giáo dục và từ thiện diễn ra vào ngày 28 tháng 10 năm 2023, từ 1 giờ chiều đến 4 giờ 30 chiều tại Đại học Kinh tế và Luật, Thành phố Hồ Chí Minh, Việt Nam.',
      "Sự kiện này nhằm mục đích trao quyền cho thế hệ tiếp theo, đặc biệt là Thế hệ Z, với kiến ​​thức và cơ hội trong thế giới công nghệ Web3 đang phát triển nhanh chóng. Những người tham dự đã có được những hiểu biết sâu sắc về cách mạng\n khái niệm về Web3 và lý do tại sao nó sẵn sàng trở thành công nghệ hàng đầu trong tương lai. Hơn nữa, các chuyên gia đã cung cấp hướng dẫn về các bộ kỹ năng thiết yếu, bắt đầu từ đâu và cách tạo dựng con đường sự nghiệp thành công trong lĩnh vực năng động này.\n cánh đồng.",
      "Web3 cam kết hỗ trợ sinh viên có hoàn cảnh khó khăn bằng cách cung cấp\n học bổng cho những người cần hỗ trợ tài chính về học phí và chi phí sinh hoạt. Diễn giả chính Cô Mai Ngô,",
      "Sự kiện còn có phần thảo luận nhóm với các chuyên gia trong ngành, bao gồm bà Mai Ngô, ông Tuấn Anh từ BSCS và ông Đào Tiền Phong,\n Luật sư quản lý của Investpush Legal, người đã chia sẻ những hiểu biết sâu sắc có giá trị về cơ hội nghề nghiệp trong lĩnh vực công nghệ blockchain. Những người tham dự có cơ hội tham gia vào một cuộc trò chuyện ý nghĩa với các chuyên gia này,\n đạt được những kiến ​​thức và hướng dẫn quý giá.",
      "Ngoài các buổi cung cấp thông tin, Web3 và Gate Charity còn trao học bổng trong sự kiện này. 15 suất học bổng sẽ được trao cho\n học sinh có hoàn cảnh khó khăn có thành tích học tập xuất sắc, mỗi suất trị giá 1.000.000 đồng.",
      "Sự kiện dự kiến ​​sẽ mời khoảng 100 sinh viên đến từ Đại học FTC và các giảng viên. Sự kiện này cũng sẽ có sự tham gia trực tuyến đáng kể\n sự hiện diện, với lưu lượng truy cập trực tuyến dự kiến ​​là 50.000 người xem.",
      "Web3 rất vui mừng được tổ chức sự kiện này, quy tụ các chuyên gia, sinh viên và những người ủng hộ công nghệ Web3 nhằm thúc đẩy giáo dục, nhận thức và cơ hội trong lĩnh vực đầy biến đổi này.",
      "Giới thiệu về Cổng từ thiện",
      'Charity là một tổ chức từ thiện phi lợi nhuận toàn cầu tập trung vào việc thúc đẩy phong trào từ thiện blockchain trên toàn cầu. Hoạt động với sứ mệnh "Thúc đẩy một thế giới cân bằng", Charity đặt mục tiêu đoàn kết cộng đồng tiền điện tử để thu hẹp khoảng cách nghèo đói, cải thiện cuộc sống của những người kém may mắn, tối đa hóa công bằng giáo dục và thúc đẩy môi trường.\n tính bền vững thông qua các mạng blockchain và sáng kiến ​​​​tiền điện tử sáng tạo và đáng tin cậy.',
      "Tuyên bố từ chối trách nhiệm:",
      "Xin vui lòng lưu ý rằng tất cả nội dung liên quan đến hoạt động này chỉ nhằm mục đích từ thiện. Tất cả thông tin đều mang tính chất chung và không cấu thành lời khuyên chuyên môn dưới bất kỳ hình thức nào. Không có nội dung nào trong nội dung cấu thành sự chào mời, khuyến nghị, chứng thực hoặc đề nghị của bất kỳ người nào ĐẾN\n sử dụng hoặc mua bất kỳ dịch vụ hoặc sản phẩm nào của họ.Gate Charity có quyền đưa ra giải thích cuối cùng về hoạt động này và tất cả các thông tin liên quan. Vui lòng liên hệ với Gate Charity qua support@gatecharity.org nếu có bất kỳ thắc mắc nào.",
      "Cổng vào CryptoTrade hơn 1.700 loại tiền điện tử một cách an toàn, nhanh chóng và dễ dàng trên web3",
      "Đăng ký để tham gia web3 và nhận 40% hoa hồng cho những người giới thiệu để có được bộ nhớ web3\n khe bây giờ.",
      "Hãy liên hệ với chúng tôi!",
      "ĐộiNgày 23 tháng 10 năm 2023",
    ],
    4: [
      "Truy cập chuỗi khối vào\n Thay đổi cuộc sống",
      "Xây dựng một thế giới nơi mọi người có thể truy cập các dịch vụ tài chính và trợ giúp thông qua các giải pháp blockchain bất cứ khi nào và\n bất cứ nơi nào cần thiết.",
      "Tổng số tiền quyên góp\n (ĐÔ LA MỸ)",
      "Tổng cộng\n Người thụ hưởng",
      "Được hưởng lợi\n  Vùng",
      "Thúc đẩy sự phát triển cân bằng\n  trên thế giới",
      "Xóa đói nghèo",
      "Chúng tôi tin rằng blockchain và tiền điện tử có thể đóng một vai trò trong việc tạo ra nền giáo dục và việc làm bình đẳng\n cơ hội, giúp đỡ những người bị ảnh hưởng bởi tình trạng bần cùng hóa và giúp hàng triệu người thoát khỏi đói nghèo.",
      "Trao quyền cho người yếu thế",
      "Loại bỏ các rào cản đối với các nguồn lực quan trọng và hệ thống hỗ trợ, tập trung vào việc giúp đỡ phụ nữ, những người gặp khó khăn\n khuyết tật, trẻ em có hoàn cảnh khó khăn, người già và các gia đình dễ bị tổn thương ở các vùng khó khăn nhằm mang lại\n tính toàn diện, khả năng tiếp cận và khả năng phát triển và tăng trưởng.",
      "Cứu hành tinh của chúng ta",
      "Chỉ có một hành tinh và chúng ta sẽ luôn hành động để giảm thiểu thiệt hại của con người đối với khí hậu và môi trường tự nhiên. Một môi trường lành mạnh có nghĩa là tạo ra tác động tích cực trên toàn cầu đối với các thế hệ tương lai.",
      "Cung cấp viện trợ nhân đạo",
      "Cung cấp cứu trợ kịp thời cho những người bị ảnh hưởng bởi thiên tai và khủng hoảng khi tình hình trở nên nghiêm trọng nhất, bao gồm cả\n hỗ trợ những người bị ảnh hưởng bởi xung đột, tái thiết các khu vực bị thiên tai, hỗ trợ những người phải di dời và\nviện trợ cho các khu vực khó khăn về mặt y tế.",
      "Việc gây quỹ minh bạch và có thể theo dõi",
      "Sử dụng công nghệ blockchain để đạt được các hoạt động từ thiện minh bạch và đáng tin cậy nhằm thực sự giúp đỡ những người có hoàn cảnh khó khăn.",
      "Kênh gây quỹ tác phẩm nghệ thuật của NFT",
      "Với việc gây quỹ đấu giá NFT, chúng tôi có thể đạt được mục đích từ thiện một cách hiệu quả đồng thời truyền bá giá trị của\n tác phẩm nghệ thuật.",
      "Dự án trò chơi từ thiện Metaverse",
      "Các giải pháp Web3.0 đa dạng cung cấp những cách thức mang tính giáo dục và thú vị để truyền bá hạt giống từ thiện trong trái tim mọi người.",
      "Sử dụng công nghệ Blockchain để mang lại lợi ích cho người dân",
      "Công nghệ chuỗi khối hàng đầu toàn cầu",
      "Thông qua công nghệ blockchain an toàn và sáng tạo, chúng tôi đảm bảo rằng các khoản quyên góp từ thiện là minh bạch, an toàn và đáng tin cậy. Mọi người cần hỗ trợ tài chính đều được đảm bảo nhận được sự giúp đỡ về mọi mặt.",
      "Kho lưu trữ tài sản kỹ thuật số được hỗ trợ rộng rãi",
      "Chúng tôi tự hào về việc hỗ trợ nguồn tài sản kỹ thuật số rộng nhất trên thế giới, điều đó có nghĩa là bạn có thể sử dụng bất kỳ tài sản kỹ thuật số nào bạn có để tham gia vào phong trào từ thiện vĩ đại này.",
      "Truy cập vào giáo dục Blockchain",
      "Chúng tôi tin rằng giáo dục là gốc rễ của mọi thứ và việc tiến hành giáo dục blockchain cơ bản sẽ mang lại hiệu quả cho những người mà chúng tôi giúp đỡ, những người sẽ là một phần không thể thiếu trong việc xây dựng blockchain trong tương lai.",
      "Hợp tác đa phương vì mục đích từ thiện",
      "Chúng tôi cam kết tối đa hóa hiệu quả của blockchain bằng cách hợp nhất các tổ chức, dự án và chuyên gia trong ngành tốt nhất, hiểu rằng bất kỳ hình thức tích hợp nào cuối cùng cũng sẽ phục vụ mục đích từ thiện.",
      "Bảng xếp hạng",
      "Ngày 21/10, từ 8h đến 16h (GMT -3), Web3 Charity kết hợp cùng A. A. Garotos de Ouro & Gate.io tổ chức sự kiện đặc biệt Ngày trẻ em tại Arena Brahma nổi tiếng ở Brazil. Cuộc tụ họp đáng chú ý này đã thu hút khoảng 150 trẻ em từ cộng đồng gần đó, tất cả đều háo hức và phấn khởi.",
      "Web3 Charity, một tổ chức từ thiện phi lợi nhuận toàn cầu, đã tổ chức thành công sự kiện theo chủ đề “Bảo vệ đại dương” tại Baclayon, Philippines, vào ngày 6 tháng 10 năm 2023. Sự kiện này quy tụ 2 nhà môi trường địa phương và nghệ sĩ JR để đấu tranh bảo vệ hệ sinh thái biển của khu vực .",
      "Sáng kiến ​​của chúng tôi",
      "Dự án hỗ trợ khủng hoảng lương thực Somalia",
      "Tham gia Gate Charity và Worid Vision, Hỗ trợ nạn nhân nạn đói ở Somalia",
      "Nâng lên",
      "Quyên góp",
      "Quyên góp ngay bây giờ",
      "Nguyên nhân được hỗ trợ",
      "Gần 50 triệu người đang trên bờ vực chết đói ở ít nhất 45 quốc gia trên thế giới. Chúng ta chưa từng chứng kiến ​​một cuộc khủng hoảng nạn đói như thế này trong thời hiện đại, với các quốc gia ở Châu Phi, Châu Mỹ Latinh, Trung Đông và Châu Á bị ảnh hưởng. Ở những quốc gia như Somalia, hàng triệu người đang phải chịu cảnh đói khát. Những tác động bất lợi của biến đổi khí hậu, như hạn hán, lũ lụt và các thời tiết khắc nghiệt khác, đã phá hủy mùa màng và thu nhập, thậm chí gây ra đàn châu chấu khổng lồ ở một số quốc gia tàn phá mùa màng. Hậu quả của đại dịch COVID-19 đã khiến giá thực phẩm và các hàng hóa khác trên toàn cầu tăng vọt. Điều này ngăn cản các gia đình mua những thực phẩm cơ bản mà họ cần để tồn tại. Trong khi đó, xung đột ở nhiều địa điểm trên khắp thế giới đang khiến hàng triệu người phải rời bỏ nhà cửa, cuộc sống và sinh kế. Web3 Charity và World Vision sẽ chung tay cung cấp thực phẩm cho các gia đình bị ảnh hưởng bởi nạn đói ở Somalia, đặc biệt là trẻ em mà sự sống còn phụ thuộc vào viện trợ nhân đạo.",
      "Một thỏa thuận với Chương trình Lương thực Thế giới của Liên hợp quốc có nghĩa là cứ 1USDC do một người quyên góp sẽ được nhân với 27. Khoản quyên góp 1.000 USDC sẽ trở thành 27 USDC với sự đóng góp của WEP.",
      "Tác động của nhiều cuộc xung đột, bao gồm cả cuộc chiến ở Ukraine, cái nôi sản xuất lúa mì toàn cầu, đang tạo ra một cơn bão hoàn hảo cho những đứa trẻ dễ bị tổn thương nhất trên thế giới.",
      "Trong khi đó, xung đột ở nhiều địa điểm trên khắp thế giới đang khiến hàng triệu người phải rời bỏ nhà cửa, cuộc sống và sinh kế. Bạn có biết rằng 2/3 số người phải di dời trên toàn thế giới đã buộc phải rời bỏ nhà cửa vì đói không?",
      "Hậu quả của đại dịch COVID-19 đã khiến giá thực phẩm và các hàng hóa khác trên toàn cầu tăng vọt. Điều này khiến các gia đình không thể mua những thực phẩm cơ bản mà họ cần để tồn tại.",
      "Những tác động bất lợi của biến đổi khí hậu, như hạn hán, lũ lụt, bão cực đoan và thời tiết khó lường, đã phá hủy mùa màng và thu nhập, thậm chí gây ra đàn châu chấu khổng lồ ở một số quốc gia, tàn phá mùa màng.",
      "Chung tay chống nạn đói",
      "Để đối phó với cuộc khủng hoảng nạn đói chưa từng có ảnh hưởng đến gần 50 triệu người trên 45 quốc gia, web3 Charity đã hợp tác với World Vision. Sự hợp tác này nhằm mục đích cung cấp hỗ trợ lương thực quan trọng cho những người đang rất cần, đặc biệt là ở những khu vực bị nạn đói như Somalia. Sự hợp tác này tìm cách giải quyết vấn đề nghiêm trọng về nạn đói, đã đạt đến mức báo động ở nhiều khu vực khác nhau ở Châu Phi, Châu Mỹ Latinh, Trung Đông và Châu Á.",
      "Tối đa hóa tác động với Chương trình Lương thực Thế giới",
      "Tổ chức từ thiện Web3, phối hợp với World Vision, đã hợp tác với Chương trình Lương thực Thế giới của Liên hợp quốc (WFP) để tận dụng các khoản quyên góp của cá nhân. Mỗi 1 USDC do một cá nhân đóng góp sẽ được nhân lên gấp 27 lần, biến khoản quyên góp 1.000 USDC thành 27.000 USDC. Những nguồn lực này rất quan trọng trong việc cung cấp viện trợ lương thực thiết yếu cho các gia đình ở các khu vực bị nạn đói, đặc biệt là trẻ em sống dựa vào hỗ trợ nhân đạo để sinh tồn. Sáng kiến ​​này bao gồm việc phân phát các gói thực phẩm cho các gia đình và cung cấp dinh dưỡng trị liệu cho trẻ nhỏ nhất, giải quyết nhu cầu dinh dưỡng trước mắt của các em trong cuộc khủng hoảng này.",
      "Đối tác của chúng tôi",
      "Tầm nhìn Thế giới là một tổ chức phi chính phủ Cơ đốc giáo đấu tranh chống đói nghèo và thúc đẩy sự phát triển bền vững trong các cộng đồng, gia đình và trẻ em dễ bị tổn thương trên toàn thế giới. Mỗi phút, tổ chức này cung cấp nước, thực phẩm và trao quyền cho những người có nhu cầu. Với sự hiện diện ở gần 100 quốc gia, Tầm nhìn Thế giới có thể nhanh chóng ứng phó với các tình huống khẩn cấp, khủng hoảng và thiên tai. Năm 2021, tổ chức này đã giúp đỡ hơn 30,1 triệu người bị ảnh hưởng bởi các trường hợp khẩn cấp ở 52 quốc gia. Các tài khoản của World Vision được kiểm toán hàng năm để đảm bảo tính minh bạch.",
      "Hỗ trợ Trường dành cho trẻ em có hoàn cảnh khó khăn SLB Sri Soedewi của Indonesia",
      "Tham gia Gate Charity và Worid Vision, hỗ trợ nạn nhân nạn đói ở Somalia",
      "Nâng lên",
      "Quyên góp",
      "Quyên góp ngay bây giờ",
      "Nguyên nhân được hỗ trợ",
      "Được thành lập vào năm 1982, SLB Sri Soedewi dành cho người khuyết tật có hơn một trăm sinh viên, cung cấp các chương trình dành cho nhiều loại khuyết tật khác nhau, bao gồm khiếm thị, khiếm thính, khuyết tật trí tuệ, khuyết tật thể chất và chứng tự kỷ. Nó phục vụ học sinh ở các cấp tiểu học, trung học cơ sở và trung học phổ thông. Trường tập trung khai thác tiềm năng và sở thích riêng của mỗi học sinh, nuôi dưỡng khả năng học tập và các kỹ năng khác nhau nhằm đảm bảo sự phát triển toàn diện của các em. Mục tiêu cuối cùng là trang bị cho học sinh những kỹ năng sống giúp các em có thể sống tự lập trong xã hội. Tổ chức từ thiện Web3 và Trường dành cho người khuyết tật SLB Sri Soedewi cam kết thúc đẩy tính hòa nhập và cung cấp một nền tảng hỗ trợ và an toàn cho mọi trẻ em.",
      "Mỗi món quà sẽ tạo nên sự khác biệt đầy ý nghĩa trong cuộc sống của học sinh và bạn cũng có thể là một phần trong đó.",
      "Chất lượng hỗ trợ và đời sống học tập tốt cho học sinh khiếm thị có thể giúp xây dựng lòng tự trọng, giúp phát triển tình bạn quan trọng và mang lại niềm tin rằng tương lai vẫn còn để khám phá.",
      "Môi trường học tập phù hợp là sự đáp ứng lâu dài quan trọng đối với các nhu cầu văn hóa và xã hội của trẻ. SLB Sri Soedewi mang đến cơ hội phát triển hệ thống hỗ trợ cảm xúc, ngôn ngữ và học tập để sinh viên tỏa sáng.",
      "Khuyết tật không có nghĩa là trở ngại trong cuộc sống, thậm chí không phải là rào cản để họ tiếp cận và tham gia một số hoạt động. Chỉ là họ có cách thực hiện một hoạt động khác mà họ không thể làm vì tất cả đều đặc biệt.",
      "Tinh thần đoàn kết là một trong những yếu tố quan trọng để thúc đẩy sự hòa nhập và nở những nụ cười tươi, nắm tay nhau để tạo nên sự gắn kết bền chặt.",
    ],
    5: [
      "Circle công bố báo cáo thường niên đầu tiên về “Tình trạng nền kinh tế USDC”",
      "Dưới đây là lời mở đầu của báo cáo “Trạng thái nền kinh tế USDC – Chào mừng đến với giai đoạn giá trị tiện ích của tiền kỹ thuật số”.",
      "Tải xuống toàn bộ báo cáo Hiện trạng nền kinh tế USDC và xem chương trình nghị sự của chúng tôi cho Diễn đàn kinh tế thế giới #WEF23 tại Circle.com/Davos",
      "Tiền tệ kỹ thuật số bằng đô la đang thúc đẩy sự phát triển tiếp theo của tiền tệ, thanh toán và tài chính",
      "Mười năm trước, tôi đồng sáng lập Circle với tầm nhìn rằng tiền truyền thống có thể được đưa trực tiếp lên Internet dưới dạng tiền kỹ thuật số và được cung cấp trên các mạng toàn cầu mở, có thể tương tác. Việc mở ra một thế giới trao đổi giá trị không ma sát, phản ánh sự trao đổi thông tin và liên lạc không ma sát đánh dấu những thập kỷ đầu tiên của Internet đã làm sinh động công việc của Circle kể từ đó.",
      "Vào năm 2013, sự trưởng thành về công nghệ, hiểu biết về ngành và nhận thức về quy định về tiền kỹ thuật số và công nghệ chuỗi khối hầu như không tồn tại. Thật vậy, các điều kiện cần thiết để xây dựng một giao thức cho “đô la trên Internet” thậm chí còn không tồn tại. Chúng tôi biết rằng đây sẽ là - ít nhất - một hành trình từ 10 đến 20 năm và tác động của tiền kỹ thuật số lên mạng internet mở thậm chí còn sâu sắc hơn internet thông tin.",
      "Giờ đây, mười năm sau, USD Coin (USDC), loại tiền kỹ thuật số bằng đô la được quản lý hàng đầu thế giới, đang trở thành xu hướng phổ biến. Trên hầu hết mọi số liệu và xu hướng, Nền kinh tế USDC đang phát triển, lành mạnh và đang trên đường trở thành động lực chính trong việc tạo điều kiện thuận lợi cho hoạt động kinh tế ở quy mô internet - tiếp cận người dân và thị trường mà không thể thực hiện được nếu ngân hàng phụ thuộc vào cơ sở hạ tầng cố định hoặc khu vườn có tường bao bọc tài chính.",
      "Trong khi kỷ nguyên đầu cơ bitcoin và các tài sản tiền điện tử khác đã trải qua một năm đầy biến động, các loại tiền kỹ thuật số như USDC báo trước sự thay đổi cần thiết sang giai đoạn giá trị tiện ích của chuỗi khối và tiền luôn có thể lập trình, có thể tổng hợp được. Trong thời đại internet, việc thúc đẩy những đổi mới có trách nhiệm này không phải là cạnh tranh với hệ thống tài chính truyền thống mà là hoàn thành những công việc còn dang dở.",
      "Đặt cơ hội vào viễn cảnh: các loại tiền kỹ thuật số bằng đô la như USDC có thể bắt đầu hấp thụ một phần đáng kể trong hơn 2 nghìn tỷ đô la doanh thu của ngành thanh toán và trở thành một biểu hiện kỹ thuật số mới của nguồn cung tiền M2 trị giá 21 nghìn tỷ đô la.",
      "Lấy một ví dụ, các thương gia hiểu được tầm quan trọng của sự phát triển này. Hơn 85% đang ưu tiên cho phép thanh toán bằng tiền kỹ thuật số. Mặc dù còn non trẻ nhưng các khoản thanh toán tại điểm bán hàng cho USDC đã có mặt ở đây. Và nhiều nhà cung cấp thanh toán truyền thống – bao gồm Checkout.com, Mastercard, Plaid, Stripe, Visa, Worldpay từ FIS, cùng nhiều nhà cung cấp khác – đang xây dựng USDC để giúp người bán chấp nhận nó dễ dàng nhất có thể.",
      "Cơ sở hạ tầng blockchain công cộng đang trải qua quá trình nâng cấp “quay số lên băng thông rộng”, thúc đẩy các giao dịch gần như tức thời, bảo mật cao, có thể lập trình và chi phí thấp. Các nhà xây dựng và phát triển Web2 đang đổ xô vào Web3, khi ngày càng có nhiều công ty khởi nghiệp, doanh nghiệp cũng như các công ty dịch vụ tài chính và thanh toán truyền thống tìm cách kết nối vào Internet giá trị.",
      "Trong khi đó, các nhà hoạch định chính sách trên toàn cầu đang tăng cường tập trung, đưa ra các quy định rất cần thiết về cách các loại tiền kỹ thuật số đáng tin cậy có thể được giám sát – một cách nhất quán và hài hòa – bởi các cơ quan quản lý ngân hàng và thanh toán.",
      "Kể từ khi thành lập, Circle đã được thúc đẩy bởi ý tưởng rằng một hệ thống kinh tế toàn cầu mới có thể được xây dựng trên nền tảng internet – cơ sở hạ tầng internet công cộng mở, toàn cầu và có khả năng tương tác để lưu trữ và truyền tải giá trị, và cuối cùng là trung gian vốn rộng hơn. Tầm nhìn đó đang trở thành hiện thực và năm 2023 sẽ là một năm quan trọng trong quá trình phát triển tiếp theo của tiền tệ, thanh toán và tài chính.",
      "Khi báo cáo hồi tưởng hàng năm về Trạng thái nền kinh tế USDC đầu tiên của chúng tôi diễn ra, những mầm xanh của giá trị tiện ích toàn cầu đang mọc lên ở khắp mọi nơi, thúc đẩy làn sóng đổi mới dịch vụ tài chính cởi mở, có trách nhiệm, có thể cải thiện con đường dẫn đến thịnh vượng, đồng thời bảo vệ tính toàn vẹn của hệ thống tài chính toàn cầu. hệ thống.",
    ],
    6: [
      "Mới cho tình hình kinh tế USDC năm 2024",
      "Một hệ thống tài chính internet mới đang nổi lên. Nhờ sức mạnh của USDC, mạng blockchain và một số nhà phát triển và công ty công nghệ tốt nhất thế giới, hệ thống tài chính đang được nâng cấp. Nó nhanh hơn, dễ dàng hơn và an toàn hơn, kiếm tiền cho tất cả mọi người.",
      "Lời nói đầu",
      "Trong thập kỷ qua, sự phát triển của công nghệ blockchain đã thúc đẩy sự hội tụ của internet và tài chính toàn cầu. Sự hội tụ này đang mang đến một bản nâng cấp phần mềm mang tính chuyển đổi cho hệ thống tài chính.",
      "Đây là điều đã truyền cảm hứng cho việc thành lập Circle vào năm 2013. Trọng tâm của chúng tôi là đưa các yếu tố cốt lõi của phần mềm internet mở vào thế giới tiền tệ. Sứ mệnh của chúng tôi là sử dụng tính mở và quy mô của Internet để nâng cao sự thịnh vượng kinh tế toàn cầu thông qua trao đổi giá trị dễ dàng - xây dựng những gì chúng tôi tin là một hệ thống tài chính Internet mới và mới nổi.",
      "Phần mềm đã sẵn sàng nuốt chửng thế giới tài chính, giống như cách nó đã phát triển nhiều ngành công nghiệp truyền thống cách đây một thế hệ. Bằng cách làm cho hoạt động truyền thông, giải trí và các hoạt động kinh doanh khác nhanh hơn, rẻ hơn và dễ dàng tiếp cận hơn, chi phí đã giảm, các rào cản giảm bớt và sự đổi mới phát triển mạnh mẽ. Chúng tôi thấy sự di chuyển nhanh chóng của thương mại sang blockchain là làn sóng tiếp theo của xu hướng chuyển đổi kỹ thuật số kéo dài hàng thập kỷ này, với tác động kinh tế và xã hội có thể lớn hơn so với tác động của Internet ban đầu vào những năm 1990 và đầu những năm 2000.",
      "Hãy xem xét điều đó từ năm 2000-2020, việc sử dụng Internet đã tăng từ dưới 7% dân số thế giới lên 60%.1 Khi blockchain mở ra các khía cạnh của tài chính truyền thống vốn đã bị đóng cửa từ lâu, chúng tôi dự đoán một quỹ đạo tăng trưởng tương tự cho thương mại dựa trên blockchain.",
      "Trong những năm tới, chúng tôi kỳ vọng hàng triệu doanh nghiệp và hàng tỷ người sẽ bắt đầu sử dụng lớp internet Web3 mở, mới này để giao dịch trị giá hàng nghìn tỷ đô la.",
      "Tiền mở là một ý tưởng lớn, bao gồm các nguyên tắc kinh tế, kỹ thuật và triết học bắt nguồn từ thiết kế cởi mở và khả năng tương tác của Internet. Bằng cách đưa chi phí cận biên của việc phổ biến dữ liệu về 0, web đã giúp tất cả mọi người có thể tiếp cận được tổng lượng kiến ​​thức của con người. Bằng cách giúp các nhà phát triển và người sáng tạo dễ dàng xây dựng, nó đã tạo ra một hệ sinh thái trang web và ứng dụng phong phú.",
      "Tuy nhiên, dự án Internet vẫn chưa hoàn thành, bởi vì mặc dù dữ liệu được di chuyển khắp thế giới một cách an toàn, tức thời và miễn phí, siêu xa lộ tiền tệ trên Internet vẫn đang được trải nhựa.",
      "Mọi thứ chúng tôi làm hôm nay đều tập trung vào việc xây dựng và tăng tốc hệ thống tài chính internet này.",
      "Như chi tiết trong báo cáo này, tất cả bắt đầu từ nỗ lực của chúng tôi nhằm xây dựng USDC thành mạng lưới stablecoin lớn nhất, được sử dụng rộng rãi nhất trên thế giới. Thông qua USDC, chúng tôi đang trao quyền cho hầu hết mọi người, ở mọi nơi, khả năng truy cập và sử dụng đô la trên internet. Và chúng tôi đang biến USDC thành một nền tảng mở để các công ty và nhà phát triển xây dựng làn sóng ứng dụng mới có thể cạnh tranh và định hình lại nền tảng tài chính và internet thống trị ngày nay.",
      "Năm ngoái đánh dấu một bước ngoặt lớn cho tương lai của tài chính gốc dựa trên blockchain. Những kẻ xấu bắt đầu gánh chịu toàn bộ hoạt động bất chính của chúng, các hành vi gian lận bị phát hiện, tuy nhiên công nghệ cơ bản đã trưởng thành và các doanh nghiệp chính thống ngày càng tham gia sâu hơn vào các công nghệ như USDC.",
      "Các công ty thanh toán và công ty thanh toán thương mại hàng đầu hiện đang sử dụng USDC để giải quyết các điểm yếu trong thế giới thực. Các thị trường tín dụng dựa trên blockchain đang sử dụng USDC để hỗ trợ hoạt động kinh tế thực tế trên toàn thế giới. Fintech và neobanks ra đời trên internet đang chuyển sang USDC như một phương tiện di chuyển đô la với tốc độ thương mại internet. Các công ty chuyển tiền và các tổ chức phi chính phủ (NGO) đang hợp tác với USDC để giải quyết vấn đề khó khăn là chuyển giá trị một cách nhanh chóng và công bằng cho những người cần nó. Các ngân hàng truyền thống, các công ty thị trường vốn và người giám sát đang hỗ trợ USDC như một cách để phát triển hoạt động kinh doanh của họ.",
      "Động lực này là lý do tại sao các nhà hoạch định chính sách trên toàn cầu đã đạt được tiến bộ đáng kể trong việc ban hành các quy tắc và hướng dẫn về cách tham gia một cách có trách nhiệm với các stablecoin thanh toán và chuỗi khối mở. Họ hiểu tầm quan trọng của cơ hội và quyết tâm khai thác lợi ích cho nền kinh tế và xã hội của mình. Cùng nhau, các nhà đổi mới và các nhà hoạch định chính sách đang xác định lại tính nghệ thuật của những gì có thể làm được với tiền trên internet.",
      "Mặc dù vẫn còn sớm nhưng có những dấu hiệu không thể nhầm lẫn rằng USDC và hệ sinh thái rộng lớn hơn đã sẵn sàng đạt tốc độ thoát – giải phóng sức mạnh của tiền mở trên toàn thế giới.",
      "Tôi hy vọng bạn thích ấn bản năm 2024 của Tình trạng nền kinh tế USDC. Nhóm của tôi và tôi mong muốn được thảo luận về những phát hiện này với bạn.",
      "Trân trọng,",
      "Đồng sáng lập, Chủ tịch và Giám đốc điều hành",
      "Đô la cho mọi người, mọi nơi",
      "Các mạng blockchain mở đang tạo ra một dạng tiền mới và xác định lại cách mọi người và doanh nghiệp trao đổi giá trị trên toàn cầu. Các mạng này đại diện cho một lớp công nghệ tài chính mới tận dụng internet để giảm chi phí và ma sát nhúng sâu, tăng tốc độ và hình dung lại những gì có thể xảy ra khi trao đổi giá trị, có thể là gửi đô la kỹ thuật số xuyên biên giới hoặc tương tác với các phiên bản mã hóa của các tài sản truyền thống khác.",
      "Khả năng này đã thúc đẩy Circle ra mắt USDC vào năm 2018. USDC là một loại tiền ổn định thanh toán kết hợp sức mạnh, sự tin cậy và hiệu ứng mạng của đồng đô la Mỹ với siêu năng lực của internet mở.",
      "Các cơ hội ngắn hạn rất đáng kể và đang bắt đầu tạo ra tác động to lớn, được thúc đẩy bởi vai trò của đồng đô la ở trung tâm tài chính toàn cầu. Đồng đô la chiếm hơn 90% hóa đơn thương mại ở Châu Mỹ Latinh, 74% ở Châu Á Thái Bình Dương và 79% ở phần còn lại của thế giới ngoài Châu Âu.3 Theo Cục Dự trữ Liên bang, gần 1 nghìn tỷ đô la tiền giấy của Hoa Kỳ, bao gồm hai loại tiền một phần ba tổng số tờ 100 USD đang tồn tại được lưu giữ bên ngoài Hoa Kỳ.4",
      "Vai trò toàn cầu của đồng đô la có nghĩa là phần lớn hoạt động của stablecoin ngày nay được tính bằng đô la. USDC giúp hầu hết mọi người, ở mọi nơi, có thể truy cập, nắm giữ và giao dịch đô la kỹ thuật số qua internet. USDC đã có mặt ở hơn 190 quốc gia và được lưu giữ trong gần 3 triệu ví trên chuỗi.5",
      "Một nền tảng mở để kiếm tiền trên internet",
      "Trong vài năm qua, USDC đã trải qua sự tăng trưởng theo cấp số nhân với tư cách là một mạng lưới stablecoin. Kể từ khi được giới thiệu vào năm 2018, USDC đã được sử dụng để giải quyết khoảng 12 nghìn tỷ USD trong các giao dịch blockchain. Nhiều người ở nhiều quốc gia hơn đang trải nghiệm những lợi ích của việc mở khóa đô la kỹ thuật số trên blockchain. Điều này đang thúc đẩy các nhà phát triển xây dựng các ứng dụng mới, củng cố hệ sinh thái dịch vụ xung quanh USDC và giúp việc lấy, gửi và lưu trữ trở nên dễ dàng hơn.",
      "Các trường hợp sử dụng ngày càng tăng của công nghệ blockchain gợi nhớ đến quá trình áp dụng điện toán đám mây. Trong lịch sử ban đầu, đám mây được coi là lĩnh vực thích hợp và đầy rủi ro, nhưng giờ đây, những lợi thế vốn có của đám mây đối với hoạt động kinh doanh đã trở nên rõ ràng. Ngày nay, các tập đoàn lưu trữ 60% dữ liệu của họ trên đám mây, với 9 trên 10 doanh nghiệp lớn áp dụng kiến ​​trúc nhiều đám mây.6 Chúng ta đang trên đà tiến tới giai đoạn tiếp theo trong quá trình phát triển này, khi ngày càng có nhiều doanh nghiệp áp dụng Web3 trên chuỗi khối cấp doanh nghiệp . Hệ thống tài chính internet mà Circle đang giúp xây dựng không cạnh tranh với đám mây; nó giúp hoàn thành nó. Thật vậy, mạng blockchain mang lại dữ liệu, giao dịch và sức mạnh tính toán đáng tin cậy cho internet công cộng.",
      "Chúng tôi đang trên đà tiến tới giai đoạn tiếp theo trong quá trình phát triển này khi ngày càng có nhiều doanh nghiệp áp dụng Web3 trên chuỗi khối cấp doanh nghiệp.",
      "API đô la cho tài chính internet USDC về cơ bản là API đô la trên internet công cộng. Đây là một khối xây dựng mã nguồn mở được quản lý tốt, giúp tích hợp dễ dàng vào các dự án fintech, ngân hàng và tiền kỹ thuật số khác để cho phép các giao dịch gần như tức thời, gần như miễn phí bằng loại tiền được sử dụng rộng rãi nhất trên thế giới. Điều này tạo ra những cơ hội to lớn tại thời điểm phát triển nhanh chóng trong ngành công nghệ tài chính và sự phổ biến ngày càng tăng của API.",
      "Được xây dựng để có khả năng tương tác blockchain USDC vốn có sẵn trên 15 mạng blockchain. Theo thời gian, kế hoạch của chúng tôi là cung cấp USDC ở bất cứ nơi nào các nhà phát triển hoạt động và áp dụng các biện pháp bảo mật phù hợp để nó có thể lưu chuyển dễ dàng và an toàn trên hệ sinh thái blockchain.",
      "Đồng thời, chúng tôi đang làm mờ dần sự phức tạp của chuỗi khối. Giao thức chuyển chuỗi chéo (CCTP) mới ra mắt gần đây giúp giảm ma sát, tăng tính an toàn và bảo mật cũng như giảm chi phí khi gửi USDC từ chuỗi khối này sang chuỗi khối khác. Trong tương lai gần, chúng tôi mong muốn kích hoạt hỗ trợ CCTP cho mọi blockchain có USDC, giúp việc gửi đô la qua internet trở nên liền mạch mà không gặp phải những thách thức hiện tại mà người dùng gặp phải khi chuyển USDC từ blockchain này sang blockchain khác.",
      "Làm cho blockchain dễ dàng hơn cho các nhà phát triển",
      "Hiện đã có hàng chục nghìn nhà phát triển xây dựng trên mạng blockchain và chúng tôi hy vọng con số này sẽ nhân lên nhanh chóng trong những năm tới. Circle cung cấp ví có thể lập trình, mẫu hợp đồng thông minh và các dịch vụ khác để hỗ trợ sự phát triển của hệ sinh thái này và đơn giản hóa quy trình cho các doanh nghiệp và nhà phát triển xây dựng ứng dụng mới trên mạng blockchain. Các doanh nghiệp Internet đã đạt được quy mô có thể chỉ cần đưa USDC và các tài sản kỹ thuật số khác vào trải nghiệm khách hàng hiện có mà không cần phải xây dựng lại chúng từ đầu.",
    ],
    7: [
      "Tải lên đô la của bạn",
      "USDC là đồng đô la kỹ thuật số, còn được gọi là stablecoin, hoạt động 24/7 và di chuyển với tốc độ internet. USDC hoạt động tự nhiên trên internet, chạy trên nhiều chuỗi khối tiên tiến nhất thế giới. Hàng tỷ USDC được đổi chủ mỗi ngày1 và mỗi đô la kỹ thuật số của USDC luôn có thể quy đổi theo tỷ lệ 1:1 thành đô la Mỹ.",
      "Luôn luôn. Luôn luôn mở.",
      "USDC vượt qua biên giới và giờ làm việc của ngân hàng. Là một đồng đô la kỹ thuật số có phạm vi tiếp cận toàn cầu, USDC có thể có sẵn bất cứ khi nào và bất cứ nơi nào bạn cần. Thật dễ dàng để gửi USDC đi khắp thế giới, thanh toán hàng hóa và dịch vụ hoặc tiết kiệm cho tương lai. Bất kỳ ai có kết nối internet đều có thể gửi, nhận và lưu USDC.",
      "Dòng tiền tức thời. Cả ngày. Hằng ngày.",
      "Không còn phải chờ đợi để hoàn tất giao dịch và rút tiền mặt. USDC khiến khái niệm về thời gian thanh toán trở nên lỗi thời với các khoản thanh toán có thể vòng quanh thế giới và đến tài khoản của bạn nhanh như email. Hãy tưởng tượng các giao dịch FX ở mọi quy mô có thể mất vài giây để hoàn thành – đó là sức mạnh của USDC.",
      "Gửi tiền tiết kiệm chi phí",
      "Không nên tốn tiền để sử dụng tiền. Nói lời tạm biệt với việc phải trả 50 đô la để gửi chuyển khoản ngân hàng – nói xin chào với một phần xu. Người bán có thể tránh phải chịu phí 2,9% khi thanh toán bằng thẻ tín dụng, hưởng lợi từ dòng tiền tức thời và chuyển lại khoản tiết kiệm cho khách hàng.",
      "Tài chính được hình dung lại",
      "USDC đưa khả năng của tiền mặt lên một tầm cao mới. Mở ra các cơ hội trong thị trường vốn tiền điện tử để giao dịch, cho vay, vay và gây quỹ bằng USDC – có thể truy cập trên toàn cầu. Các khả năng chỉ mới bắt đầu.",
      "Nền tảng tiền mở",
      "Xây dựng sản phẩm của bạn bằng API kiếm tiền toàn cầu, nguồn mở, có thể tương tác và sử dụng miễn phí. Các nhà phát triển tin tưởng USDC như một khối xây dựng cốt lõi cho ứng dụng của họ để cung cấp các dịch vụ thanh toán, giao dịch và tài chính theo thời gian thực cho người dùng của họ. Ước mơ lớn với USDC.",
      "USDC đang chuyển đổi tài chính",
      "USDC là một phần của hệ sinh thái toàn cầu bao gồm thương mại truyền thống và tiền điện tử. Được tạo ra bởi Circle, một công ty fintech được quản lý, USDC là đồng đô la kỹ thuật số đáng tin cậy, được chấp nhận rộng rãi và có tính thanh khoản cao.",
      "Sự ổn định mà bạn có thể tin tưởng",
      "USDC luôn có thể quy đổi theo tỷ lệ 1:1 thành đô la Mỹ. Dự trữ USDC được nắm giữ dưới sự quản lý và giám sát của các tổ chức tài chính hàng đầu của Hoa Kỳ, bao gồm BlackRock và BNY Mellon.",
      "Xem các chứng thực hàng tháng để đảm bảo rằng số tiền dự trữ USDC lớn hơn hoặc bằng số tiền đang lưu hành mỗi tháng, theo các tiêu chuẩn chứng thực do Viện Kế toán Công chứng Hoa Kỳ (AICPA) đặt ra.",
      "Circle được quản lý như một công ty chuyển tiền được cấp phép theo luật tiểu bang Hoa Kỳ giống như PayPal, Stripe và Apple Pay. Báo cáo tài chính của Circle được kiểm toán hàng năm.",
      "USDC ở khắp mọi nơi",
      "Chọn hành trình của bạn vào thế giới đô la kỹ thuật số. Tìm hiểu sâu hơn để tìm hiểu tất cả các cách doanh nghiệp, người dùng và nhà phát triển sử dụng USDC – hoặc nhận USDC ngay bây giờ.",
      "Các doanh nghiệp",
      "Đẩy nhanh tốc độ tăng trưởng toàn cầu của bạn bằng các khoản thanh toán USDC nhanh như chớp mang lại dòng tiền tức thì. Truy cập thanh khoản USDC để giao dịch, vay, cho vay và đầu tư trên các thị trường vốn tiền điện tử. Cách tốt nhất để truy cập USDC",
      "Hệ sinh thái",
      "Trải nghiệm thế hệ tiền tiếp theo với USDC. Gửi, chi tiêu, tiết kiệm và trao đổi đô la kỹ thuật số với bất kỳ ai, ở bất kỳ đâu trên thế giới, 24/7 với tốc độ internet.",
      "Nhà phát triển",
      "Tích hợp USDC vào ứng dụng của bạn ngay hôm nay – USDC là đồng đô la kỹ thuật số có thể lập trình, có nguồn mở, có thể tổng hợp và mọi người có thể sử dụng để xây dựng.",
      "Tại sao các nhà lãnh đạo chọn USDC",
      "Cho dù đó là tính minh bạch hay khả năng lập trình, USDC đều được xây dựng cho tương lai.",
      "Xem lý do các nhà đổi mới tại Consensus 2023 xây dựng bằng Circle",
      "Chúng tôi đã hỏi các đối tác của mình tại sao họ chọn Circle và USDC và thông điệp vang dội là Circle và USDC được xây dựng khác nhau. Circle giúp sử dụng và triển khai nhanh chóng, dễ dàng, đồng thời quỹ dự trữ giúp USDC trở nên khác biệt trong ngành stablecoin.",
      "Khám phá cách những người sáng lập Circle Ventures sử dụng USDC",
      "Mặc dù sự tin cậy và minh bạch có vẻ giống như những khoản đặt cược trên bảng, nhưng đó là lý do chính khiến những người sáng lập các công ty trong danh mục đầu tư của Circle Ventures chọn USDC. Các lý do khác là tính thanh khoản có thể lập trình được, tốc độ thanh toán gần như ngay lập tức, tính hiệu quả, v.v.",
      "Thiết lập tiêu chuẩn cho pháp luật về stablecoin",
      "USDC thường được xem là kế hoạch chi tiết cần tuân theo để thiết kế một loại tiền kỹ thuật số được quản lý. Ở cấp độ cao nhất, Circle hợp tác chặt chẽ với các nhà lãnh đạo quốc hội Hoa Kỳ để điều chỉnh các giá trị mở của Web3 với hệ thống tài chính truyền thống và giúp tạo ra tương lai hài hòa, kết nối của chúng ta.",
      "USDC có phải là tiền điện tử không?",
      "USDC là một loại tiền ổn định được dự trữ đầy đủ, là một loại tiền điện tử hoặc tài sản kỹ thuật số. Không giống như các loại tiền điện tử khác có giá biến động, USDC được thiết kế để duy trì mức giá tương đương với đồng đô la Mỹ. USDC là một kho lưu trữ giá trị ổn định được hưởng lợi từ tốc độ và tính bảo mật của công nghệ blockchain.",
      "Làm cách nào tôi có thể tin tưởng rằng USC sẽ luôn có thể quy đổi theo tỷ lệ 1:1 thành đô la Mỹ?",
      "USDC là đồng đô la kỹ thuật số được hỗ trợ 100% bằng tiền mặt và tài sản tương đương tiền mặt có tính thanh khoản cao và luôn có thể quy đổi theo tỷ lệ 1:1 thành đô la Mỹ. Một phần dự trữ USDC được đầu tư vào Quỹ dự trữ vòng tròn (USDXX), quỹ thị trường tiền tệ do SEC quản lý do BlackRock quản lý. Báo cáo độc lập hàng ngày của bên thứ ba về danh mục đầu tư được cung cấp công khai. Xem thêm trên trang Minh bạch của chúng tôi.",
      'Việc USDC được "đúc" hoặc "đốt" có nghĩa là gì?',
      "Các doanh nghiệp có thể mở tài khoản Circle Mint để đổi đô la Mỹ lấy USDC. Khi một doanh nghiệp gửi USD vào tài khoản Circle Mint của họ, Circle sẽ phát hành số USDC tương đương cho doanh nghiệp. Quá trình phát hành USDC mới được gọi là “đúc tiền”. Quá trình này tạo ra USDC mới đang lưu hành.",
      "Tương tự, khi doanh nghiệp muốn đổi USDC lấy đô la Mỹ, doanh nghiệp có thể gửi USDC vào tài khoản Circle Mint của họ và yêu cầu nhận đô la Mỹ miễn phí. Quá trình đổi USDC này được gọi là “đốt cháy”. Quá trình này đưa USDC ra khỏi lưu thông.",
      "Khi đô la Mỹ được hoán đổi lấy USDC trên một sàn giao dịch tài sản kỹ thuật số, sàn giao dịch thường sẽ cung cấp số dư USDC mà họ có sẵn để thực hiện việc hoán đổi. Nếu sàn giao dịch cần nhiều USDC hơn để thực hiện hoán đổi, sàn giao dịch đó thường sẽ sử dụng tài khoản Circle Mint của mình để đúc thêm USDC.",
      "Tôi cần gì để truy cập USDC?",
      "Một cách nhanh chóng và dễ dàng để các doanh nghiệp truy cập USDC là đăng ký tài khoản Circle Mint.",
      "USDC cũng có thể được truy cập nhanh chóng từ các sàn giao dịch tài sản kỹ thuật số như Coinbase, Crypto.com và Kraken.",
      "Các nhà phát triển có thể bắt đầu xây dựng trên USDC ngay hôm nay bằng cách truy cập kho lưu trữ GitHub của chúng tôi. Các tài nguyên bổ sung có sẵn từ trang USDC dành cho nhà phát triển của chúng tôi.",
      "USC có sẵn trên những blockchain nào?",
      "USDC vốn có sẵn cho 15 blockchain: Algorand, Arbitrum, Avalanche, Base, Ethereum, Flow, Hedera, NEAR, Noble, OP Mainnet, Polkadot, Polygon PoS, Solana, Stellar và TRON – với nhiều dự kiến ​​hơn trong năm nay và hơn thế nữa.",
      "USDC cũng đã được kết nối với nhiều blockchain mới nổi bởi các cầu nối của bên thứ ba, dẫn đến việc tạo ra các dạng USDC bắc cầu như USDC.e.",
      "Để biết thêm chi tiết, hãy xem trang USDC dành cho nhà phát triển của chúng tôi.",
      "USDC khác với CBC (tiền kỹ thuật số của ngân hàng trung ương) như thế nào?",
      "USDC được phát hành bởi Circle, một công ty thuộc khu vực tư nhân, trong khi CBDC sẽ do chính phủ phát hành. Trong khi hầu hết CBDC chỉ đang trong giai đoạn nghiên cứu thì USDC vẫn tồn tại cho đến ngày nay và được hàng triệu người trên thế giới sử dụng rộng rãi. Circle đã phát triển công nghệ cho phép USDC chạy trên 11 chuỗi khối công cộng, với sự đổi mới của thị trường tư nhân và nguồn mở thúc đẩy tiến bộ nhanh chóng trong các mô hình tiền kỹ thuật số bằng đồng đô la.",
      "Đọc thêm về những hiểu biết sâu sắc của Circle về cuộc thảo luận về CBDC của Cục Dự trữ Liên bang.",
    ],
    8: [
      "Đồng USD (USDC) là gì?",
      "Hợp đồng thông minh là một thỏa thuận kỹ thuật số tự thực hiện cho phép hai hoặc nhiều bên trao đổi tiền, tài sản, cổ phiếu hoặc bất kỳ thứ gì có giá trị một cách minh bạch, không xung đột trong khi tránh sự cần thiết của bên thứ ba.",
      "Để trình bày nó theo cách đơn giản nhất, bạn có thể so sánh hợp đồng thông minh với máy bán hàng tự động cho các giao dịch phức tạp.",
      "Hợp đồng thông minh hoạt động bằng cách tuân theo các câu lệnh “nếu/khi…thì…” đơn giản được viết thành mã trên blockchain. Một mạng máy tính thực hiện các hành động khi các điều kiện xác định trước được đáp ứng và xác minh. Những hành động này có thể bao gồm việc giải ngân cho các bên thích hợp, đăng ký phương tiện, gửi thông báo hoặc xuất vé. Sau đó, blockchain sẽ được cập nhật khi giao dịch hoàn tất. Điều đó có nghĩa là giao dịch không thể thay đổi và chỉ những bên đã được cấp phép mới có thể xem kết quả.",
      "Với hợp đồng thông minh, bạn chỉ cần thả bitcoin vào máy bán hàng tự động (tức là sổ cái) và ký quỹ, chứng thư, hợp đồng, hàng hóa, giấy phép lái xe hoặc bất cứ thứ gì mà hợp đồng dùng để chỉ đơn giản rơi vào tài khoản của bạn. Hợp đồng thông minh thực hiện tất cả công việc để xác định xem các điều kiện của đơn hàng có được đáp ứng hay không.",
      "Hợp đồng thông minh vừa xác định các quy tắc và hình phạt xung quanh một thỏa thuận giống như cách hợp đồng truyền thống thực hiện, vừa tự động thực thi các nghĩa vụ đó.",
      "Hợp đồng thông minh kiếm tiền như thế nào?",
      "Có hai phương pháp trực tuyến chính để các nhà phát triển hợp đồng thông minh kiếm tiền từ công việc của họ: bắt đầu tính năng Double Profit cần thiết để sử dụng giao thức, cộng với việc blockchain sẽ gửi 90% lợi nhuận từ phần thưởng nhận được cho người dùng ký hợp đồng thông minh tại chuỗi khối, Hợp đồng thông minh = Kế hoạch tiết kiệm, trong đó chuỗi khối gửi một tỷ lệ phần trăm đáng kể đến kho lưu trữ chuỗi khối và sau đó kiếm lợi nhuận từ việc bán nhiều hơn khi giá của mã thông báo tăng lên. Tính phí hoa hồng gấp đôi khi sử dụng hợp đồng thông minh.",
      "Để cho bạn một ví dụ: hợp đồng thông minh tương tự như nguyên tắc nhận lãi kép cho khoản tiền gửi cố định tại ngân hàng",
      "Hợp đồng thông minh có rủi ro không?",
      "Hợp đồng thông minh là mã tự thực thi trên nền tảng blockchain đã thay đổi ngành công nghiệp bằng cách tự động hóa các quy trình và cho phép giao dịch mà không cần sự tin cậy. Do đó, chúng cũng được đánh giá cao bởi tài chính blockchain và cho đến nay, chúng tôi chưa gặp trường hợp nào mà hợp đồng thông minh có rủi ro.",
      "Được rồi, vậy hợp đồng thông minh tồn tại ở đâu? Họ bị xử tử ở đâu? Và chúng hoạt động như thế nào?",
      "NHỮNG GÌ BẠN CẦN BIẾT",
      "Hợp đồng thông minh là những dòng mã tự thực thi với các điều khoản trong thỏa thuận giữa người mua và người bán được tự động xác minh và thực hiện thông qua mạng máy tính.",
      "Nick Szabo, một nhà khoa học máy tính người Mỹ, người đã phát minh ra loại tiền ảo có tên “Bit Gold” vào năm 1998,1 đã định nghĩa hợp đồng thông minh là các giao thức giao dịch được vi tính hóa thực hiện các điều khoản của hợp đồng.2",
      "Hợp đồng thông minh được triển khai trên chuỗi khối giúp các giao dịch có thể theo dõi, minh bạch và không thể đảo ngược.",
      "Lợi ích của hợp đồng thông minh Độ chính xác, tốc độ và hiệu quả",
      "Hợp đồng được thực hiện ngay lập tức khi một điều kiện được đáp ứng.",
      "Bởi vì hợp đồng thông minh là hợp đồng kỹ thuật số và tự động nên không cần phải xử lý thủ tục giấy tờ.",
      "Không mất thời gian để sửa các lỗi có thể xảy ra khi điền tài liệu bằng tay.",
      "Sự tin cậy và minh bạch",
      "Không cần phải lo lắng về việc thông tin bị giả mạo vì lợi ích cá nhân vì không có bên thứ ba tham gia và nhật ký giao dịch được mã hóa được trao đổi giữa những người tham gia",
      "Hợp đồng thông minh được xác minh, thực thi và thực thi bởi một chương trình máy tính chạy trên mạng blockchain. Khi cả hai bên tham gia hợp đồng thông minh đồng ý với các điều khoản của nó, chương trình sẽ tự động thực thi. Điều này giúp loại bỏ sự cần thiết của bên thứ ba vì hợp đồng được xác minh và thực thi bởi mạng blockchain.",
      "Bởi vì các hợp đồng thông minh được thực thi bằng mã chứ không phải bằng con người nên chúng loại bỏ khả năng xảy ra lỗi của con người và có thể tự động hóa nhiều tác vụ mà theo truyền thống đòi hỏi sự tương tác của con người.",
      "Một trong những điều tốt nhất về blockchain là vì đây là một hệ thống phi tập trung tồn tại giữa tất cả các bên được phép nên không cần phải trả tiền cho người trung gian (người trung gian) và nó giúp bạn tiết kiệm thời gian cũng như tránh xung đột.",
      "Chắc chắn, Blockchain có những vấn đề riêng, nhưng không thể phủ nhận rằng chúng nhanh hơn, rẻ hơn và an toàn hơn các hệ thống truyền thống. Đây là lý do tại sao chúng ta thấy nhiều hợp đồng thông minh hơn được thực thi trên các mạng blockchain khác nhau bao gồm Ethereum, Solana, Tezos, Hyperledger, v.v.",
      "Nạp thêm tiền vào ví của bạn",
      "Tạo trải nghiệm sáng tạo với NFT, giao thức DeFi, USDC, v.v. Thêm nhiều chức năng hơn vào ví của bạn bằng cách tích hợp bất kỳ hợp đồng thông minh nào.",
      "Tăng lòng trung thành và sự gắn kết của khách hàng",
      "Xây dựng những trải nghiệm mới, độc đáo để tương tác với khách hàng và tăng mức độ trung thành, bao gồm phần thưởng NFT, điểm khách hàng thân thiết hoặc mã thông báo giới hạn có thể đổi lấy trải nghiệm trực tiếp.",
      "Hoán đổi, đặt cọc hoặc cầu",
      "Thu hẹp khoảng cách với DeFi bằng cách tích hợp liền mạch các giao thức DeFi vào ứng dụng của bạn bằng các hợp đồng thông minh được nhúng.",
      "Quy mô với USDC",
      "Tận dụng các hợp đồng thông minh để dễ dàng kích hoạt và quản lý các giao dịch USDC có lập trình như bảng lương, đăng ký hoặc thanh toán trong tài khoản của bạn.",
      "Đưa tương tác Web3 vào ứng dụng của bạn nhanh hơn",
      "Vitalik Buterin về Hợp đồng thông minh",
      "Như Vitalik Buterin, người sáng lập ethereum 28 tuổi, đã giải thích tại Hội nghị thượng đỉnh DC Blockchain: “Trong cách tiếp cận hợp đồng thông minh, một tài sản hoặc tiền tệ được chuyển vào một chương trình và chương trình sẽ chạy mã này và tại một thời điểm nào đó, nó sẽ tự động xác thực một điều kiện và nó tự động xác định xem tài sản sẽ được chuyển cho người này hay trả lại cho người khác hay liệu tài sản đó có nên được hoàn trả ngay lập tức cho người đã gửi hay không hoặc một số sự kết hợp của chúng.”",
      "Đồng thời, sổ cái phi tập trung cũng lưu trữ và sao chép tài liệu mang lại cho nó sự bảo mật và bất biến nhất định.",
      "Ví dụ: Ký quỹ cho các giao dịch",
      "Giả sử bạn thuê một căn hộ từ tôi.",
      "Bạn có thể thực hiện việc này thông qua blockchain bằng cách thanh toán bằng tiền điện tử. Bạn nhận được biên nhận được lưu giữ trong hợp đồng ảo của chúng tôi; Tôi sẽ cung cấp cho bạn khóa nhập kỹ thuật số sẽ đến với bạn vào một ngày cụ thể. Nếu khóa không đến đúng giờ, blockchain sẽ hoàn lại tiền.",
      "Nếu tôi gửi chìa khóa trước ngày thuê, chức năng sẽ giữ nó, giải phóng cả phí và chìa khóa cho bạn và tôi khi đến ngày thuê.",
      "Hệ thống hoạt động dựa trên tiền đề If-Then và được chứng kiến ​​bởi hàng trăm người, vì vậy bạn có thể mong đợi việc giao hàng không có lỗi.",
      "Nếu tôi đưa chìa khóa cho bạn, tôi chắc chắn sẽ được trả tiền. Nếu bạn gửi một số tiền nhất định bằng bitcoin, bạn sẽ nhận được chìa khóa. Tài liệu sẽ tự động bị hủy sau thời gian quy định và một trong hai chúng tôi không thể can thiệp vào mã mà người kia không biết vì tất cả những người tham gia đều được cảnh báo đồng thời.",
      "Xác định các trường hợp sử dụng hợp đồng thông minh trong đời thực",
      "Jerry Cuomo, phó chủ tịch phụ trách công nghệ blockchain tại IBM, tin rằng các hợp đồng thông minh có thể được sử dụng trên toàn chuỗi, từ dịch vụ tài chính, chăm sóc sức khỏe đến bảo hiểm. Dưới đây là một số ví dụ.",
      "Ứng dụng DeFi",
      "Một sự thay đổi lớn trong cách chúng tôi quản lý tiền đang diễn ra khi chúng tôi thấy Ứng dụng DeFi đang phát triển thành xu hướng phổ biến.",
      "Tài chính phi tập trung là một lối thoát khỏi các dịch vụ và chuẩn mực ngân hàng truyền thống. Đây là một quá trình chuyển đổi rất tế nhị nhưng dường như là không thể tránh khỏi.",
      "Chúng tôi có những con số để chứng minh điều đó: Giá trị giao dịch hàng năm của DeFi đã tăng 14 lần vào năm 2020 và tăng hơn bốn lần lên 112,07 tỷ USD vào năm 2021 với tổng giá trị hơn 20 tỷ USD chỉ riêng trong hợp đồng thông minh DeFi.",
      "Hợp đồng thông minh trong DeFi đang tạo điều kiện thuận lợi cho việc trao đổi hàng hóa, dịch vụ, dữ liệu, tiền, v.v. Người dùng của các tổ chức tài chính tập trung, chẳng hạn như ngân hàng và hiệp hội tín dụng, dựa vào các trung gian để thực hiện giao dịch. Trong khi đó, DApp đang sử dụng hợp đồng thông minh để đảm bảo rằng mọi hành động đều chân thực, minh bạch và không có lỗi của con người.",
      "Chỉ trong vài năm, NFT đã bùng nổ trên thị trường, với mức vốn hóa thị trường đạt con số khổng lồ 40,9 tỷ USD vào năm 2021 khi chúng trở thành trường hợp sử dụng thành công nhất của hợp đồng thông minh.",
      "NFT được tạo thông qua quy trình đúc tiền yêu cầu các hợp đồng thông minh được triển khai trên blockchain.",
      "Hợp đồng thông minh là một công cụ cho phép thực hiện thỏa thuận mua bán giữa chủ sở hữu NFT và người mua. Hợp đồng thông minh chứa thông tin về NFT, chẳng hạn như người tạo ra tác phẩm, các bên khác được hưởng tiền bản quyền mỗi khi NFT được bán và lịch sử quyền sở hữu của tác phẩm.",
      "Phần lớn NFT không được ghi lại trên blockchain vì việc lưu giữ quá nhiều dữ liệu trên blockchain vừa tốn kém vừa tốn nhiều năng lượng. Do đó, các hợp đồng thông minh thường bao gồm một liên kết đến công việc mà chúng đại diện và chỉ chủ sở hữu mới có thể xem được.",
      "Chính phủ",
      "Những người trong cuộc khẳng định rằng hệ thống bỏ phiếu của chúng tôi cực kỳ khó bị gian lận, nhưng tuy nhiên, hợp đồng thông minh sẽ xoa dịu mọi lo ngại bằng cách cung cấp một hệ thống an toàn hơn vô cùng. Các phiếu bầu được bảo vệ bằng sổ cái sẽ cần phải được giải mã và yêu cầu sức mạnh tính toán quá cao để truy cập. Không ai có sức mạnh tính toán lớn đến thế, nên phải cần đến Chúa mới hack được hệ thống!",
      "Thứ hai, hợp đồng thông minh có thể làm tăng tỷ lệ cử tri đi bỏ phiếu thấp. Phần lớn sức ì đến từ một hệ thống dò dẫm bao gồm xếp hàng, xuất trình danh tính của bạn và điền vào các biểu mẫu. Với hợp đồng thông minh, các tình nguyện viên có thể chuyển bỏ phiếu trực tuyến và thế hệ Millennials sẽ bỏ phiếu hàng loạt cho POTUS của họ.",
      "Ứng dụng DeFi",
      "Đối với Cuộc bầu cử Tổng thống Hoa Kỳ năm 2020, Quận Utah đã thành công trong việc thu thập phiếu bầu từ các cử tri vắng mặt thông qua một ứng dụng di động hỗ trợ blockchain có tên Voatz. Danh tính cử tri được ẩn đằng sau một chữ ký/giá trị băm duy nhất và dữ liệu liên quan cũng được bảo mật trong blockchain mà không có các mối đe dọa từ bên ngoài.",
      "Chuỗi khối không chỉ cung cấp một sổ cái duy nhất làm nguồn tin cậy mà còn loại bỏ những trở ngại có thể xảy ra trong giao tiếp và quy trình làm việc vì tính chính xác, minh bạch và hệ thống tự động của nó.",
      "Thông thường, hoạt động kinh doanh phải trải qua nhiều lần, trong khi chờ phê duyệt và các vấn đề nội bộ hoặc bên ngoài tự giải quyết. Một sổ cái blockchain hợp lý hóa việc này. Nó cũng loại bỏ những khác biệt thường xảy ra khi xử lý độc lập và có thể dẫn đến các vụ kiện tốn kém cũng như sự chậm trễ trong việc giải quyết.",
      "Lịch sử trường hợp",
      "Vào năm 2015, Depository Trust & Clearing Corp. (DTCC) đã sử dụng sổ cái blockchain để xử lý số chứng khoán trị giá hơn 1,5 triệu đô la, đại diện cho 345 triệu giao dịch.",
      "Chuỗi cung ứng ô tô",
      "Không còn nghi ngờ gì nữa, chúng ta đang tiến bộ từ những động vật có xương sống lười biếng trước con người thành những robot siêu thông minh. Hãy nghĩ về một tương lai nơi mọi thứ đều được tự động hóa. Google đang đạt được điều đó với điện thoại thông minh, kính thông minh và thậm chí cả ô tô thông minh. Đó là nơi hợp đồng thông minh có thể giúp ích.",
      "Một ví dụ là phương tiện tự lái hoặc tự đỗ, trong đó các hợp đồng thông minh có thể phát huy tác dụng như một loại ‘nhà tiên tri’ có thể phát hiện ra ai có lỗi trong một vụ va chạm; cảm biến hoặc trình điều khiển, cũng như vô số biến số khác. Bằng cách sử dụng hợp đồng thông minh, một công ty bảo hiểm ô tô có thể tính mức phí khác nhau dựa trên địa điểm và điều kiện mà khách hàng đang vận hành phương tiện của họ.",
      "Địa ốc",
      "Nếu bạn muốn cho ai đó thuê căn hộ của mình, bạn phải trả tiền cho người trung gian như Craigslist hoặc báo để quảng cáo, sau đó bạn lại phải trả tiền cho ai đó để xác nhận rằng người đó đã trả tiền thuê nhà và làm theo.",
      "Một giải pháp phi tập trung có thể giúp cắt giảm chi phí của bạn. Tất cả những gì bạn làm là thanh toán qua tiền điện tử và mã hóa hợp đồng của bạn trên hợp đồng thông minh. Mọi người đều nhìn thấy và bạn hoàn thành việc thực hiện một cách tự động. Các nhà môi giới, đại lý bất động sản, người cho vay tiền cứng và bất kỳ ai liên quan đến trò chơi bất động sản đều có thể kiếm được lợi nhuận.",
      "Hợp đồng thông minh mang tính cách mạng trong việc thay đổi các hoạt động bất động sản hiện tại.",
      "Hợp đồng thông minh đang thay thế hợp đồng truyền thống như một thỏa thuận duy nhất giữa người bán và người mua. Nó tự động thực hiện các yêu cầu ngay khi các điều kiện cụ thể của hợp đồng được đáp ứng.",
      "Hợp đồng thông minh đảm bảo sự tin cậy thông qua một phiên bản duy nhất của sự thật bằng cách thiết lập niềm tin. Tất cả các bên bao gồm ngân hàng, đại lý và người cho vay thế chấp đều có thể ký thỏa thuận thông qua hợp đồng thông minh. Vì các giao dịch được lưu giữ trên blockchain nên sổ cái chung này cho phép các bên liên quan xem xét quy trình bất cứ lúc nào và từ bất cứ đâu.",
      "Chăm sóc sức khỏe",
      "Hồ sơ sức khỏe cá nhân có thể được mã hóa và lưu trữ trên blockchain bằng khóa riêng chỉ cấp quyền truy cập cho các cá nhân cụ thể. Chiến lược tương tự có thể được sử dụng để đảm bảo rằng nghiên cứu được thực hiện thông qua luật HIPAA (một cách an toàn và bí mật).",
      "Biên lai phẫu thuật có thể được lưu trữ trên blockchain và tự động gửi đến các nhà cung cấp bảo hiểm dưới dạng bằng chứng giao hàng. Sổ cái cũng có thể được sử dụng để quản lý chăm sóc sức khỏe nói chung, chẳng hạn như giám sát thuốc, tuân thủ quy định, kết quả xét nghiệm và quản lý vật tư chăm sóc sức khỏe.",
      "Hợp đồng thông minh thật tuyệt vời!",
      "Đây là những gì hợp đồng thông minh mang lại cho bạn:",
      "Quyền tự trị",
      "Bạn là người đưa ra thỏa thuận; không cần phải nhờ đến nhà môi giới, luật sư hoặc các bên trung gian khác để xác nhận. Ngẫu nhiên, điều này cũng loại bỏ nguy cơ bị bên thứ ba thao túng, vì việc thực thi được quản lý tự động bởi mạng, thay vì bởi một hoặc nhiều cá nhân, có thể thiên vị, có thể mắc lỗi.",
      "Lòng tin",
      "Tài liệu của bạn được mã hóa trên sổ cái chung. Không thể nào ai đó có thể nói rằng họ đã đánh mất nó.",
      "Hỗ trợ",
      "Hãy tưởng tượng nếu ngân hàng của bạn mất tài khoản tiết kiệm của bạn. Trên blockchain, mỗi người bạn của bạn đều có sự hỗ trợ của bạn. Tài liệu của bạn được nhân đôi nhiều lần.",
      "Sự an toàn",
      "Mật mã, mã hóa trang web, giữ tài liệu của bạn an toàn. Không có hack. Trên thực tế, cần phải có một hacker cực kỳ thông minh mới có thể bẻ khóa và xâm nhập được.",
      "Hợp đồng thông minh cung cấp tính bảo mật của mạng blockchain. Bản thân công nghệ này có khả năng cung cấp loại bảo mật phù hợp để ngăn chặn khả năng bị hack, tuy nhiên, việc viết hợp đồng thông minh cũng đảm bảo an toàn cho nó.",
      "Tiết kiệm",
      "Hợp đồng thông minh giúp bạn tiết kiệm tiền vì chúng loại bỏ sự hiện diện của bên trung gian. Ví dụ, bạn sẽ phải trả tiền cho một công chứng viên để chứng kiến ​​giao dịch của bạn.",
      "Sự chính xác",
      "Hợp đồng tự động không chỉ nhanh hơn và rẻ hơn mà còn tránh được các lỗi do điền thủ công hàng đống biểu mẫu.",
      "Hợp đồng thông minh thật tuyệt vời!",
      "Một phần tương lai của hợp đồng thông minh nằm ở việc vướng vào các vấn đề mà chúng ta đã thảo luận trước đó.",
      "Ví dụ, ở Cornell Tech, các luật sư khẳng định rằng hợp đồng thông minh sẽ đi vào cuộc sống hàng ngày của chúng ta, đã cống hiến hết mình để nghiên cứu những mối quan tâm này.",
      "Đặc biệt là khi Thị trường NFT đã xuất hiện, hơn bao giờ hết, có nhiều mối đe dọa hơn bao giờ hết để giải quyết các khía cạnh có vấn đề của hợp đồng thông minh.",
      "Hợp đồng thông minh có thể tác động đến những thay đổi trong một số ngành nhất định, đặc biệt là luật. Điều này là do các luật sư sẽ chuyển từ việc viết hợp đồng truyền thống sang sản xuất các mẫu hợp đồng thông minh được tiêu chuẩn hóa, tương tự như các hợp đồng truyền thống được tiêu chuẩn hóa mà bạn sẽ tìm thấy trên LegalZoom.",
      "Người mua thương mại, công ty tín dụng và kế toán cũng có thể sử dụng hợp đồng thông minh cho các nhiệm vụ, chẳng hạn như kiểm toán thời gian thực và đánh giá rủi ro.",
      "Khi chúng ta thấy nhiều Ứng dụng DeFi hơn đang được triển khai trên khắp thế giới và thị trường NFT tăng giá trị, rõ ràng là các Hợp đồng thông minh đang hợp nhất thành một sự kết hợp giữa nội dung giấy và kỹ thuật số, nơi các hợp đồng được xác minh thông qua blockchain và được chứng minh bằng bản sao vật lý.",
      "Hợp đồng thông minh sẽ tiếp tục được cải thiện và khi công nghệ phát triển, chúng sẽ ngày càng được tin cậy và hiệu quả.",
      "Chúng tôi, tại Blockgeeks, cung cấp các khóa học chi tiết về công nghệ Blockchain bao gồm Phát triển Ethereum bao gồm tất cả các chủ đề quan trọng mà bạn cần để bắt đầu với Hợp đồng thông minh!",
    ],
    9: [
      "NFT đang thay đổi nghệ thuật kỹ thuật số, sưu tầm thể thao, phương tiện truyền thông, v.v.",
      "Hệ sinh thái này đang cách mạng hóa cách thức hàng hóa kỹ thuật số",
      "được tạo ra, phân phối và thu thập",
      "Vũ trụ NFT đang phát triển nhanh chóng với:",
      "Chuỗi khối",
      "nơi NFT được lưu trữ và đúc.",
      "Chợ",
      "nơi NFT được liệt kê và giao dịch.",
      "Các dự án NFT",
      "bản thân họ, từ nghệ thuật sáng tạo đến bất động sản ảo và hơn thế nữa.",
      "Nhưng trước khi khám phá hệ sinh thái này, hãy định nghĩa NFT.",
      "Tìm hiểu tính linh hoạt của Qut",
      "NFTS LÀ GÌ?",
      "NFT là các token được bảo mật bằng mật mã với lịch sử quyền sở hữu và chủ sở hữu hiện tại được lưu trữ trên blockchain.",
      "Chúng cho phép thị trường sơ cấp và thứ cấp phát triển mạnh mẽ cho hàng hóa kỹ thuật số, loại bỏ những người trung gian và cho phép người sáng tạo tận dụng tối đa công việc của họ.",
      "CÓ THỂ VS.",
      "KHÔNG THỂ thay thế được",
      "Tính linh hoạt là khi các đơn vị riêng lẻ của hàng hóa có thể thay thế cho nhau và không thể phân biệt được về giá trị.",
      "Tiền tệ như đô la Mỹ có thể thay thế được. Mỗi đô la Mỹ đều có thể hoán đổi cho nhau và có cùng giá trị như bất kỳ đô la nào khác.",
      "Mã thông báo không thể thay thế (NFT) hoặc hàng hóa có các thuộc tính duy nhất, làm cho nó có giá trị khác với các mã thông báo hoặc hàng hóa tương tự khác.",
      "Một ngôi nhà có sân thượng được đánh giá khác nhau và không thể thay thế cho một ngôi nhà không có sân thượng.",
      "Sân hiên có thể là một trong nhiều đặc tính khác nhau khiến ngôi nhà không thể thay thế được và có giá trị khác nhau.",
      "Tương tự, mã thông báo không thể thay thế sẽ có các thuộc tính nghệ thuật khác nhau hoặc các thuộc tính khác khiến nó trở nên độc đáo so với các mã thông báo khác, mang lại cho nó một giá trị duy nhất.",
      'Cryptopunks là một bộ sưu tập NFT gồm 10.000 pixel nghệ thuật "punks", không có hai trò chơi chữ nào giống nhau.',
      "Các thuộc tính khác nhau với độ hiếm khác nhau làm cho một số trò chơi chữ nhất định trở nên hiếm hơn và có giá trị cao hơn.",
      "Trong khi sự khan hiếm các thuộc tính có xu hướng quyết định việc định giá thì sở thích thẩm mỹ và chủ quan của các nhà đầu tư và nhà sưu tập cũng có thể ảnh hưởng đến việc định giá.",
      "Có 78 trò chơi chữ có thuộc tính “răng xô” và 259 trò chơi chữ có thuộc tính “hoodie”, trò chơi chữ “hoodie” có giá trị gấp ba lần giá trị của trò chơi chữ “răng xô”.",
      "Nguồn: Phòng thí nghiệm Larya",
      "Giá trị đô la được tính với giá ETH là 4.430 USD",
      "Giá trị Punk tính đến ngày 29 tháng 11 năm 2021",
      "thợ mỏ hùng mạnh",
      "NỀN TẢNG BLOCKCHAIN ​​NFTS LƯU TRỮ",
      "Vốn hóa thị trường",
      "Hầu hết các blockchain này sử dụng sự đồng thuận bằng chứng cổ phần, tiêu thụ ít năng lượng hơn đáng kể so với bằng chứng công việc.",
      "Ethereum được thiết lập để chuyển đổi sang bằng chứng cổ phần vào năm tới.",
      "Tezos có một cộng đồng NFT chuyên dụng và là một trong những nền tảng NFT không phải Ethereum đầu tiên",
      "Nguồn: Messari.io",
      "Kể từ ngày 29 tháng 11 năm 2021",
      "KHÁM PHÁ TIÊU CHUẨN TOKEN ETHEREUM",
      "Ethereum là nền tảng lớn nhất dành cho NFTS và NFT chiếm một phần lớn trong hoạt động chuyển giao và hoạt động của mạng.",
      "Cùng với token ERC 721:",
      "Tiêu chuẩn hợp đồng ERC-20 được sử dụng để tạo ra các token có thể thay thế được và được sử dụng phổ biến nhất trên mạng Ethereum nói chung.",
      "Tiêu chuẩn mã thông báo ERC-1155 được Enjin tạo ra và có thể tạo ra cả tài sản có thể thay thế và không thể thay thế.",
      "ERC-998 là phần mở rộng của tiêu chuẩn ERC-721 cho phép NFT sở hữu mã thông báo NFTS và ERC-20.",
      "Các tiêu chuẩn mã thông báo NFT của Ethereum có thể tương tác với các dapp (ứng dụng phi tập trung) cũng được xây dựng trên chuỗi khối Ethereum, cho phép tích hợp liền mạch.",
      'Dịch vụ tên Ethereum NFT thay đổi tên ví Ethereum của bạn thành địa chỉ “.eth" đã chọn để có tên địa chỉ duy nhất và dễ nhớ hơn.',
      "Các NFT này được mua trong khoảng thời gian hàng năm, tương tự như tên miền web.",
      "Một vùng biển rộng mở của các thị trường thứ cấp",
      "Hiện tại có rất nhiều thị trường thứ cấp để mua và bán NFT.",
      "OpenSea đã nổi lên như một nền tảng thống trị. với khối lượng giao dịch là 1,9 tỷ USD vào tháng 11 năm 2021.",
      "Ngày nay NFT ở đâu",
      "NGHỆ THUẬT KỸ THUẬT SỐ, BỘ SƯU TẬP, CHƠI GAME VÀ NHIỀU HƠN NỮA",
      "NFT có thể không chỉ là bằng chứng kỹ thuật số về quyền sở hữu mà còn có thể được liên kết với tài sản hoặc lợi ích vật chất.",
      "Album mới nhất của Kings of Leon When You See Yourself đã được phát hành cùng với các NFT trong thời gian giới hạn khác nhau gắn liền với tài sản vật chất và lợi ích.",
      "NFT trị giá 50 đô la Úc (35,70 bảng Anh) NFT cung cấp cho chủ sở hữu quyền truy cập vào bản tải xuống kỹ thuật số của âm nhạc, đĩa vinyl vật lý của album và quyền truy cập vào hàng hóa kỹ thuật số.",
      "Sáu “NFT vé vàng” cung cấp cho người sở hữu quyền truy cập trọn đời vào bốn ghế ở hàng ghế đầu tại bất kỳ buổi biểu diễn nào của ban nhạc.",
      "Bộ sưu tập NFT của nghệ sĩ người Anh Damien Hirst The Money khám phá cách mọi người đánh giá NFT và nghệ thuật vật chất.",
      "Tiền tệ là một tập hợp gồm 10.000 NFT duy nhất, mỗi NFT có các tác phẩm vật lý tương ứng được tạo vào năm 2016",
      "Các tác phẩm vật lý được cất giữ trong một kho tiền ở Vương quốc Anh và người mua NFT có thời gian một năm (cho đến ngày 27 tháng 7 năm 2022) để quyết định xem họ sẽ giữ NFT hay phiên bản vật lý, còn phiên bản còn lại sẽ bị hủy sau khi quyết định được đưa ra.",
      "Hiện tại, không gian NFT có ba nhánh chính, mỗi nhánh thúc đẩy sự đổi mới theo những cách riêng:",
      "Từ bộ sưu tập ảnh hồ sơ đến nghệ thuật sáng tạo và các bản nhạc cộng tác, NFT đang tạo điều kiện cho nghệ thuật kỹ thuật số phát triển. Cho dù đó là bộ sưu tập âm nhạc hay thể thao, không chỉ có NFT nghệ thuật thị giác.",
      "Không gian trò chơi NFT đầy rẫy sự đổi mới, khi quyền sở hữu của người chơi đối với tài sản và giao dịch mua trong trò chơi cũng như các mô hình chơi để kiếm tiền đang được triển khai và khám phá bởi nhiều dự án khác nhau",
      "NFT đang xác định lại quyền sở hữu, danh tính và không gian kỹ thuật số, mã hóa vùng đất kỹ thuật số trong thực tế ảo, cùng với hình đại diện 3D sẽ đại diện cho chủ sở hữu của chúng.",
      "VƯỢT QUÁ KỸ THUẬT SỐ.",
      "Mặc dù NFT đã có thời điểm bùng nổ nhưng sự bùng nổ vẫn còn lâu mới kết thúc.",
      "Cùng với các lĩnh vực này, NFT vẫn đang được khám phá như một cách để mã hóa và cho phép chuyển giao kỹ thuật số các tài sản trong thế giới thực như nhà ở hoặc các tác phẩm nghệ thuật vật lý.",
      "Hầu hết mọi tài sản vật chất hoặc hàng hóa đều có thể được mã hóa để có bằng chứng và trao đổi quyền sở hữu dễ dàng hơn, đồng thời mở ra các khả năng như phân đoạn, thế chấp hóa và các hợp đồng thông minh tiện ích có thể cung cấp.",
      "Lập bản đồ hệ sinh thái NFT",
      "NFT đã trở thành chủ đề nóng nhất và thị trường sôi động nhất năm 2021, với khối lượng bán hàng tăng 100 lần đồng thời trở thành chủ đề thảo luận trên các chương trình trò chuyện buổi tối.",
      "Tiền điện tử phải mất gần một thập kỷ để thực sự thâm nhập vào xu hướng phổ biến, nhưng NFT chỉ cần vài năm để thu hút sự chú ý của mọi người. Khi các thương hiệu như Budweiser, Visa và Adidas đã mua NFT và gia nhập thị trường, rõ ràng NFT không chỉ là một xu hướng mới hấp dẫn khác.",
      "Đồ họa thông tin do Next Decentrum tài trợ này xác định NFT và khám phá hệ sinh thái hưng thịnh đã nhanh chóng phát triển xung quanh chúng. Khám phá ý nghĩa không thể thay thế, nơi NFT đang được đúc và giao dịch cũng như tương lai của loại tài sản này.",
      "NFT là gì và Khả năng thay thế là gì?",
      "NFT là các token không thể thay thế được, có lịch sử quyền sở hữu và quyền sở hữu hiện tại được bảo mật bằng mật mã trên blockchain. Những mã thông báo này có thể đại diện cho bất cứ thứ gì, cho dù đó là một tác phẩm nghệ thuật kỹ thuật số ở dạng jpeg hay một bài hát dưới dạng tệp mp3.",
      "Bằng cách lưu trữ các giao dịch của các mã thông báo này trên blockchain, chúng tôi có thể có bằng chứng kỹ thuật số về quyền sở hữu và thị trường cho những hàng hóa kỹ thuật số này mà không sợ chi tiêu gấp đôi hoặc giả mạo các giao dịch và quyền sở hữu trong quá khứ.",
      "Tìm ra tính linh hoạt",
      "Tất cả điều này nghe có vẻ khá giống với tiền điện tử, vậy điều gì khiến NFT trở nên đặc biệt? Tính không thể thay thế của chúng. Không giống như các loại tiền điện tử như bitcoin hoặc ethereum, mã thông báo không thể thay thế đại diện cho hàng hóa hoặc tài sản có thuộc tính và thuộc tính duy nhất, cho phép chúng có các giá trị duy nhất ngay cả khi chúng là một phần của cùng một bộ sưu tập.",
      "Có thể thay thế: Hàng hóa có các đơn vị có thể hoán đổi cho nhau và không thể phân biệt được về giá trị. Ví dụ: đô la Mỹ, bitcoin, mã thông báo arcade",
      "Không thể thay thế: Hàng hóa có các đặc tính độc đáo, mang lại giá trị duy nhất khi so sánh với hàng hóa tương tự. Ví dụ: bất động sản, tranh vẽ, NFT.",
      "Bộ sưu tập NFT phổ biến nhất, Cryptopunks, là một bộ sưu tập gồm 10.000 pixel nghệ thuật “punks”, với các thuộc tính khác nhau như mũ, kính, kiểu tóc khác nhau, v.v. Sự kết hợp ngẫu nhiên của các thuộc tính với mức độ khan hiếm khác nhau dẫn đến mỗi punk có một giá trị duy nhất.",
      "Sự khan hiếm và sở thích thẩm mỹ chủ quan thúc đẩy việc định giá tiền điện tử và các NFT khác, cùng với các yếu tố khác như ý nghĩa lịch sử của chúng và thậm chí cả blockchain mà chúng được lưu trữ cũng ảnh hưởng đến giá trị của chúng.",
      "So sánh các chuỗi khối có khả năng NFT",
      "Có nhiều blockchain khác nhau có khả năng đúc và lưu trữ NFT, trong đó Ethereum hiện là blockchain lớn nhất và được sử dụng nhiều nhất theo vốn hóa thị trường và khối lượng giao dịch.",
      "Nghệ thuật kỹ thuật số, trò chơi, Metaverse và tương lai của NFT",
      "NFT đã gây được tiếng vang lớn vào năm 2021, mang đến cho người sáng tạo các mạng lưới kỹ thuật số và phi tập trung, nơi họ có thể lưu trữ và trao đổi công việc của mình.",
      "Hiện tại, các trường hợp sử dụng kỹ thuật số đầu tiên đang đi đầu trong quá trình phát triển NFT, với quyền sở hữu tài sản hoặc hàng hóa trong trò chơi trong hai trường hợp sử dụng chính đang được khám phá. Tuy nhiên, NFT có thể được sử dụng để mã hóa các tài sản vật chất như bất động sản, tác phẩm nghệ thuật vật chất, v.v., mở ra khả năng gần như vô tận cho ứng dụng của chúng.",
      "Từ việc loại bỏ rào cản về thủ tục giấy tờ và quan liêu trong các sàn giao dịch bất động sản ngày nay cho đến việc cho phép dễ dàng phân chia quyền sở hữu tài sản, các trường hợp sử dụng hữu hình trong thế giới thực của NFT mới bắt đầu được khám phá.",
    ],
    10: [
      "Cho dù bạn ghét hay yêu Web3, thì nó vẫn đang rất nóng vì những người có ảnh hưởng đến công nghệ như nhà đầu tư mạo hiểm Marc Andreesen, cựu Giám đốc điều hành Twitter Jack Dorsey và giám đốc Tesla Elon Musk gần đây đang tranh luận về nó.[1] Những người ủng hộ Web3 cũng tin rằng nó đang định hình lại bối cảnh kinh tế. Gartner ước tính rằng Web3 sẽ tăng GDP toàn cầu thêm 1,76 nghìn tỷ USD vào năm 2030. [2] Sự nổi lên của Web3 chắc chắn sẽ đặt ra nhiều vấn đề pháp lý như quản trị ảo, quyền riêng tư dữ liệu, bảo vệ sở hữu trí tuệ, tuân thủ quy định và vi phạm. Những điều này cũng mang lại nhiều cơ hội kinh doanh cho các luật sư công nghệ và các công ty luật tham gia vào lĩnh vực luật đang phát triển nhanh chóng này.",
      "Web3 là gì?",
      "Web3 là một nền tảng mới được xây dựng trên công nghệ chuỗi khối và phi tập trung thuộc sở hữu của người xây dựng và người dùng, nơi mọi người có thể liên kết tiền điện tử, NFT và siêu dữ liệu với nhau thành một nền tảng mới. Trên Web3, các cá nhân có thể cung cấp dịch vụ trực tiếp cho từng người dùng và trao đổi các mặt hàng có giá trị vốn đã tồn tại trong hệ thống. Một ví dụ về ứng dụng/mạng/nền tảng Web3 có thể là metaverse (một vũ trụ 3 chiều, trực tuyến kết hợp nhiều không gian ảo trong đó người dùng có thể cộng tác, gặp gỡ, chơi trò chơi và giao lưu) hoạt động trên một chuỗi khối phi tập trung thay vì sử dụng ngân hàng.",
      "Web3 có cần được pháp luật bảo vệ không?",
      "Có một quan niệm sai lầm rằng nền tảng phi tập trung không được kiểm soát. Ngoài ra, tài sản kỹ thuật số đang phát triển nhanh chóng ở nhiều nơi trên thế giới, nơi các cơ quan quản lý địa phương đã đưa ra luật và quy định quản lý các giao dịch. Ví dụ: Mạng lưới Thực thi Tội phạm Tài chính của Bộ Tài chính Hoa Kỳ (FinCEN) đang phát triển các quy tắc để theo dõi các sàn giao dịch tiền điện tử và báo cáo các hoạt động đáng ngờ. Ngoài ra, Úc và Canada coi Bitcoin là tài sản tài chính có giá trị có thể bị đánh thuế. Ở châu Á, quốc hội Nhật Bản đã thông qua dự luật vào tháng 6 để điều chỉnh stablecoin hoặc tiền điện tử nhằm bảo vệ nhà đầu tư.",
      "Những lo lắng về pháp lý sẽ gia tăng trong metaverse khi nó đang phát triển mạnh mẽ trên toàn thế giới. Nhìn chung, nhiều người dùng tin rằng NFT cung cấp bằng chứng không thể phủ nhận về quyền sở hữu. Tuy nhiên, mọi chuyện có thể không đơn giản như chúng ta nghĩ. Ví dụ, quyền sở hữu tài sản, theo João Marinotti, Giáo sư Luật tại Đại học Indiana, vì quyền sở hữu đất ảo thuộc luật hợp đồng chứ không phải luật tài sản. Ngoài ra, việc mua NFT không cung cấp cho người dùng quyền sở hữu tài sản kỹ thuật số.",
      "Mặc dù Web3 là một bối cảnh hoàn toàn mới và phức tạp nhưng cần có một hình thức bảo vệ pháp lý mới khi có sự cố xảy ra. Các vụ kiện tụng về tiền điện tử đang tăng vọt. Tranh chấp phát sinh với NFT liên quan đến cấp phép và quyền sở hữu chung đối với một tài sản. Các công ty ngày nay sử dụng metaverse như một phần trong chiến lược kinh doanh và tiếp thị của họ, điều này đặt ra nhiều vấn đề pháp lý liên quan đến quản trị, bảo vệ quyền sở hữu trí tuệ và vi phạm.",
      "Cơ hội trong Web3",
      "Nếu bất kỳ bối cảnh công nghệ nào muốn phát triển hết tiềm năng, nó cần phải có khung pháp lý phù hợp bao gồm các quyền và nghĩa vụ được đặt ra để bảo vệ lợi ích của tất cả các bên. Các vấn đề pháp lý mà chúng tôi cần giải quyết bao gồm thuế, quản trị ảo, thách thức pháp lý, cấp phép và quyền sở hữu, theo dõi và báo cáo giao dịch, quyền riêng tư dữ liệu và bảo vệ IP.",
      "Để tránh bị bỏ lại phía sau, các công ty luật, luật sư và sinh viên luật truyền thống cần hiểu rõ sự phát triển của công nghệ Web3 cũng như những thay đổi về luật và quy định liên quan đến Web3 và chính quyền khu vực.",
    ],
    11: [
      "Thẻ ghi nợ tiền điện tử hoạt động như thế nào",
      "Thẻ ghi nợ tiền điện tử được sử dụng bởi các công ty chuyên về dịch vụ tài chính tiền điện tử, chẳng hạn như Coin. Các công ty này có thỏa thuận với các mạng thanh toán như Visa và Mastercard cho phép người dùng sử dụng tiền điện tử nơi hệ thống thanh toán truyền thống được chấp nhận.",
      "Quá trình này đảm bảo các giao dịch của bạn được an toàn và minh bạch. Hơn nữa, nó cung cấp tỷ lệ chuyển đổi nhanh chóng mà không phải chờ hàng giờ hoặc hàng ngày để quá trình chuyển đổi hoàn tất.",
      "Chọn thẻ ghi nợ tiền điện tử phù hợp",
      "Trong phần này của bài viết, tôi sẽ hướng dẫn bạn cách nhận thẻ ghi nợ tiền điện tử để giao dịch hàng ngày liền mạch. Chuyển đổi tiền điện tử của bạn thành tiền pháp định và mua sắm ở bất kỳ nơi nào chấp nhận thẻ ghi nợ hợp lệ. Tôi cũng sẽ mô tả cho bạn những yếu tố bạn cần chú ý khi nhận được thẻ ghi nợ tiền điện tử tốt nhất từ ​​một số lựa chọn trên thị trường:",
      "•Tiền điện tử được hỗ trợ: Để có được thẻ ghi nợ tiền điện tử tốt nhất, bạn phải đảm bảo rằng thẻ đó hỗ trợ tiền điện tử của bạn.",
      "• Phí: Thẻ ghi nợ tiền điện tử thường tính nhiều loại phí khác nhau, chẳng hạn như phí nạp, phí giao dịch và phí rút tiền ATM. Trước khi chọn thẻ, hãy so sánh mức phí của các thẻ khác nhau.",
      "• Phần thưởng: Chọn thẻ ghi nợ tiền điện tử có chương trình phần thưởng hoàn lại tiền để tối đa hóa chi tiêu của bạn. Tìm kiếm, so sánh và chọn thẻ ghi nợ tiền điện tử tốt nhất với số tiền hoàn lại cao nhất.",
      "•Bảo mật: Thẻ ghi nợ tiền điện tử sử dụng nhiều tính năng bảo mật khác nhau để bảo vệ tiền điện tử của bạn. Chọn thẻ ghi nợ tiền điện tử tốt nhất cung cấp các tính năng bảo mật mạnh mẽ.",
      "•Hỗ trợ khách hàng: Để có được ví tiền điện tử có thẻ ghi nợ tốt nhất, một trong những điểm chính bạn cần xem xét là chất lượng hỗ trợ; nếu bộ phận hỗ trợ không thể giúp bạn khi gặp vấn đề, bạn sẽ mất tiền và thời gian, vì vậy hãy đảm bảo chọn hỗ trợ hợp lệ.",
      "Chúng là một giải pháp sáng tạo cho phép bạn sử dụng tài sản kỹ thuật số của mình trong các giao dịch trong thế giới thực. Bằng cách sử dụng công nghệ blockchain, những thẻ này có thể tạo điều kiện cho các giao dịch an toàn và minh bạch có thể được sử dụng ở bất kỳ nơi nào thẻ ghi nợ chính được chấp nhận.",
      "Một trong những sàn giao dịch tiền điện tử lớn nhất của Mỹ cho biết hôm thứ Sáu rằng họ đang hợp tác “chặt chẽ” với quy trình thanh toán toàn cầu về chuyển giao và áp dụng tài sản kỹ thuật số liền mạch.",
      "Coinbase, sàn giao dịch tiền điện tử có trụ sở tại San Francisco được biết đến với cách tiếp cận tuân thủ quy định đối với tiền điện tử, hôm nay cho biết họ “tự hào được làm việc với các nhà đổi mới như Visa” để thúc đẩy việc áp dụng tiền kỹ thuật số:",
      "Sàn giao dịch cho biết các công ty như Visa đang giúp thu hẹp khoảng cách giữa tiền điện tử và tài chính truyền thống. Điều này tạo ra một môi trường chín muồi để kết nối tiền điện tử với mạng lưới toàn cầu của mình đồng thời tập hợp các nhà lãnh đạo trong không gian [truyền thống và tiền điện tử].",
      "Visa đưa ra cách tiếp cận tiền kỹ thuật số",
      "Công ty thanh toán khổng lồ Visa, trị giá 420 tỷ đô la và thực hiện hơn 25.000 giao dịch mỗi giây trên mạng của mình, đã đưa ra kế hoạch về tiền điện tử trong một bài đăng trên blog vào đầu tuần này.",
      "Với tên gọi “Thúc đẩy cách tiếp cận của chúng tôi với tiền kỹ thuật số”, công ty đặt tài sản kỹ thuật số vào trung tâm của chiến lược mới, ưu tiên kỹ thuật số để khai thác nhiều thị trường hơn và cải thiện tính toàn diện tài chính trên toàn thế giới.",
      "Visa lưu ý rằng họ đang tích cực làm việc với các nền tảng tiền kỹ thuật số được cấp phép và quản lý như Coinbase để cung cấp cầu nối giữa các loại tiền kỹ thuật số và mạng lưới toàn cầu hiện có gồm 61 triệu người bán của chúng tôi.",
      "Công ty chỉ ra rằng hơn 25 ví tiền kỹ thuật số hiện được liên kết với mạng Visa, “cung cấp cho người dùng một cách dễ dàng để chi tiêu từ số dư tiền kỹ thuật số của họ bằng cách sử dụng thẻ ghi nợ Visa hoặc thông tin xác thực trả trước”.",
      "Những phát triển khác bao gồm những đổi mới như Visa Direct — cầu nối tiền pháp định sang tiền điện tử tức thì của công ty giúp chuyển đổi nhanh chóng các loại tiền kỹ thuật số của người tiêu dùng và bổ sung những cải tiến đó để đẩy thông tin xác thực Visa của họ.",
      "Visa cho biết những nỗ lực này giúp thương hiệu trở nên tối ưu để được các ví tiền kỹ thuật số chấp nhận - ví sau này “mong muốn nâng cao giá trị của mình cho người dùng bằng cách giúp việc chi tiêu tiền kỹ thuật số trên toàn thế giới nhanh hơn và dễ dàng hơn”.",
      "Stablecoin là một phần quan trọng của nền kinh tế tương lai",
      "Trong chiến lược rộng hơn nhằm triển khai tiền điện tử trong khu vực công, Visa cho biết họ đang liên lạc với các nhà hoạch định chính sách và tổ chức quản lý để thông báo cho các công ty về lợi ích của tiền kỹ thuật số.",
      "Giao dịch bằng thẻ ghi nợ tiền điện tử",
      "Sử dụng ví tiền điện tử bằng thẻ ghi nợ là một cách dễ dàng để chi tiêu tiền điện tử của bạn mà không cần phải chuyển đổi và đợi quá trình kết thúc. Nhà cung cấp của bạn sẽ tự động chuyển đổi tiền điện tử của bạn thành tiền tệ pháp định và chuyển nó.",
      "Sử dụng thẻ ghi nợ tiền điện tử cho các giao dịch hàng ngày",
      "Chúng hoạt động giống như thẻ ghi nợ truyền thống, cho phép bạn sử dụng chúng ở bất kỳ nơi nào chấp nhận Visa hoặc Mastercard. Chúng tạo điều kiện thuận lợi cho các giao dịch hàng ngày và mang lại lợi thế so với thẻ truyền thống, chẳng hạn như các chương trình thưởng bằng tiền mặt hoặc điểm tiền điện tử.",
      "Tính năng bảo mật của thẻ ghi nợ tiền điện tử",
      "Thẻ ghi nợ tiền điện tử sử dụng mã hóa, xác thực hai yếu tố, chống gian lận, bảo hiểm FDIC và tuân theo các quy định bảo mật tương tự như thẻ truyền thống để bảo vệ mã hóa và thông tin cá nhân của bạn.",
      "Lợi ích của việc sử dụng thẻ ghi nợ tiền điện tử",
      "Nếu bạn nhận được thẻ ghi nợ tiền điện tử, bạn sẽ nhận được nhiều lợi ích như sự tiện lợi, phần thưởng và toàn quyền kiểm soát tài sản của mình do tính phân cấp.",
    ],
    12: [
      "Tài khoản tiết kiệm USD Coin (USDC): Kiếm lãi từ tiền điện tử",
      "Web3 có danh sách tùy chọn ngày càng phong phú để bạn lựa chọn cho các tài khoản tiết kiệm tiền điện tử. Những tài khoản tiết kiệm rủi ro thấp, lợi nhuận cao này giúp người dùng kiếm được tiền lãi từ tiền điện tử lên tới 200% mỗi tháng + lãi kép. USD Coin (USDC) là một trong những loại tiền ổn định được sử dụng nhiều nhất đang lưu hành. Dưới đây là một số thông tin về nó và thông tin bổ sung về cách dễ dàng tích lũy lãi suất tiền điện tử trên YouHodler.",
      "Đồng USD (USDC) là gì?; Cách kiếm tiền điện tử",
      "Không giống như nhiều loại tiền điện tử khác, USDC là một loại tiền điện tử độc đáo được gọi là “stablecoin”. USDC được hỗ trợ bởi Đô la Mỹ với tỷ lệ 1:1. Do đó, bạn luôn có thể đổi 1 USD Coin lấy 1,00 USD Đô la Mỹ. Điều này dẫn đến một tài sản ổn định hơn nhiều so với Bitcoin hoặc các loại tiền điện tử khác, khiến nó trở thành nơi an toàn để lưu trữ tài sản.",
      "USDC là mã thông báo dựa trên Ethereum, nghĩa là bạn có thể lưu trữ nó trên ví tương thích với Ethereum như ví USDC của YouHodler chẳng hạn. Nhóm đằng sau USDC đã thiết kế đồng tiền ổn định này để giúp người dùng di chuyển đô la dễ dàng trên khắp thế giới từ ví tiền điện tử đến các doanh nghiệp, sàn giao dịch và những người khác. YouHodler đã thêm nó vào dòng sản phẩm của chúng tôi như một lựa chọn tuyệt vời khác để kiếm lãi bằng tiền điện tử trên nền tảng.",
      "Tài khoản tiết kiệm USDC: Tại sao kiếm lãi từ tiền điện tử với web3",
      "Web3 cung cấp nhiều cách khác nhau để kiếm lợi nhuận từ tài sản kỹ thuật số và với tài khoản tiết kiệm tiền điện tử, người dùng có thể mở khóa các công cụ đầu tư lợi nhuận cao, rủi ro thấp. Dưới đây là một số lý do để kiếm tiền lãi từ tiền điện tử với Web3:",
      'Kiếm lãi suất lên tới 2,6% và 10% USDC cho tiền điện tử: Kiếm lãi suất hàng ngày 2,6% và 10% USDC mỗi năm trên Web3. Con số này cao hơn nhiều so với bất kỳ tài khoản tiết kiệm ngân hàng truyền thống "lợi suất cao" nào và các khoản đầu tư khác. Web3 cung cấp cho khách hàng tỷ giá hối đoái USDC tốt nhất trên thị trường.',
      "Giữ an toàn cho tài sản: Tất cả các quỹ tiền điện tử trên Web3 đều được bảo vệ bằng các phương pháp hay nhất trong ngành khi lưu trữ. Tiền luôn được lưu trữ 100% trong ví nóng. Chúng tôi sử dụng phương pháp lưu trữ kết hợp giữa ví nóng và ví lạnh để đảm bảo tiền của người dùng được bảo vệ ở mức độ lớn nhất.",
      "Lãi suất tiền điện tử được trả 4 giờ một lần mà không mất bất kỳ khoản phí nào: vào cuối mỗi khoảng thời gian thanh toán 4 giờ, tất cả tiền lãi tiền điện tử kiếm được sẽ được ghi có vào tiền gửi tài khoản tiết kiệm của người dùng. Người dùng có thể chọn giải phóng và rút tiền từ tài khoản tiết kiệm sang ví hoặc giữ tiền trong tài khoản để kiếm thêm lãi suất tiền điện tử trong chu kỳ thanh toán tiếp theo. (Cần lưu ý số lần rút lãi từ tài khoản là 3 lần/ngày, mỗi lần rút phải lớn hơn 1 USDC)",
      "Khám phá những cách khác để kiếm lãi tiền điện tử với Web3",
      "USDC Coin Interest chỉ là một lựa chọn để kiếm lãi từ tiền điện tử thông qua Web3. Truy cập trang Kiếm tiền lãi của chúng tôi để xem nhiều loại tiền ổn định và tiền điện tử ổn định để lựa chọn. Kiếm tiền lãi từ Bitcoin, Binance Coin, v.v. Kiếm tiền điện tử bằng tài khoản tiết kiệm và khám phá cách HODL hiệu quả hơn.",
      "Ứng dụng ví tiết kiệm USDC trả lãi",
      "Ứng dụng Ví Wwb3 là ứng dụng duy nhất cho phép bạn lưu trữ Tiền xu USD (USDC) giống như trong ví thông thường, đồng thời kiếm lãi trên số dư ví USDC của bạn cứ sau 4 giờ.",
      "Lãi suất USDC hoạt động như thế nào",
      "Bạn có thể kiếm lãi trên USDC giống như cách bạn làm với tài khoản tiết kiệm ngân hàng truyền thống, nhưng có một điểm khác biệt chính. Chúng tôi cung cấp cho bạn mức lãi suất lên tới 10% trên ETH, thay vì lãi suất gần bằng 0 của ngân hàng.",
      "Cách kiếm USDC miễn phí",
      "Dễ thôi! Chỉ cần gửi USDC vào ví Web3 của bạn và bạn có thể kiếm ETH miễn phí cứ sau 4 giờ.",
      "Tại sao tiền lãi kiếm được bằng ETH?",
      "Sau khi bạn lưu trữ USDC trong ví của mình, tiền lãi bạn nhận được cứ sau 4 giờ là tiền điện tử ETH. Tuy nhiên, ETH bạn nhận được trong blockchain không thể rút trực tiếp vào ví của bạn. Bạn cần đăng nhập vào blockchain Web3 ETH bạn nhận được từ chiến thắng trong tài khoản của mình phải được chuyển đổi thành USDC trước khi có thể rút về\n   ví của bạn. Sau đây là giải thích chi tiết về cách chuyển đổi ETH bạn nhận được từ chiến thắng trong tài khoản Web3 của mình thành USDC.",
      "Trước khi rút lãi, đầu tiên bạn cần nhập tài khoản Web3, sau đó nhấn Exchange, rồi nhấn Convert all để chuyển đổi tất cả.",
      "Bước tiếp theo là rút USDC bạn đã trao đổi từ tài khoản Web3 về ví của mình. Sau đây là giải thích chi tiết về việc rút USDC.",
      "Bây giờ bạn cần nhấp vào Rút ​​tiền, sau đó nhấp vào Tổng số dư và cuối cùng nhấp vào Xác nhận. Việc rút lợi nhuận của bạn đã hoàn tất. Việc rút USDC thông thường sẽ đến trong vòng 10 phút. Nếu mạng bị tắc nghẽn, việc đến nơi sẽ bị trì hoãn.",
      "Lãi tích lũy USDC và ETH",
      "Có, nếu bạn giữ USDC cũng như ETH trong tài khoản Web3 của mình, bạn sẽ kiếm được tiền lãi tích lũy. Chúng tôi tính lãi kép cứ sau 4 giờ.",
      "Lợi suất và lợi tức hàng ngày của USDC",
      "So với các nền tảng khác, chúng tôi cung cấp thu nhập theo ngày thay vì APR. Lãi suất ngày trả cho bạn tiền lãi mà không ảnh hưởng đến lãi suất kép. Vì vậy, với những ngày Web3, bạn sẽ nhận được nhiều USDC hơn vào cuối tháng do tiền lãi tích lũy.",
      "Máy tính tiết kiệm USDC",
      "Chỉ cần sử dụng Công cụ tính tiết kiệm USDC của chúng tôi để xem bạn sẽ kiếm được bao nhiêu sau khi nắm giữ Web3 trong 3, 6 hoặc 12 tháng.",
    ],
    13: [
      "3.000.000 ETH trong lần phát hành này được lấy từ Thỏa thuận cho vay DeFi được ký giữa người khai thác và chuỗi khối. Theo Thỏa thuận cho vay DeFi, khi Thỏa thuận cho vay DeFi được ký giữa những người khai thác và chuỗi khối đạt 3.000.000 ETH, chuỗi khối có quyền chuyển 3.000.000 ETH theo cách của dự án chuỗi khối, để giữ dữ liệu của chuỗi khối cũng như để hiển thị giá trị của chính ETH, do đó blockchain sẽ trả lãi suất theo số tiền gốc USDC được người dùng lưu trữ và người dùng lưu trữ USDC sẽ nhận được tiền lãi bằng ETH.",
      "1 Tiền lãi ETH đến từ đâu?",
      "Những người khai thác sẽ vay ETH mà họ khai thác từ blockchain thông qua việc cho vay giao thức DeFi, hiện tại, blockchain đã nhận được 3 triệu ETH từ việc cho vay giao thức DeFi của người khai thác, vì vậy blockchain đã khởi xướng dự án DeFi để lưu trữ USDC để kiếm lợi nhuận cùng với các ví Web3 lớn, cho đến khi 3 triệu ETH này được gửi đi theo hình thức lãi suất, sau đó nó có thể tham gia vào dự án lợi nhuận lưu trữ blockchain tiếp theo, theo kinh nghiệm trước đây, 3 triệu ETH này sẽ được phân phối sau 3-5 tháng.",
      "Ví dụ: nếu thỏa thuận cho vay DeFi của người khai thác với blockchain đạt 3 triệu ETH, thì blockchain có quyền kết hợp ETH đã cam kết của người khai thác với các ví Web3 chính để làm cho nó có tính thanh khoản phản ánh giá trị của chính ETH.",
      "Ngoài ra, chúng tôi cần nhắc bạn rằng khi bạn trở thành người dùng bộ lưu trữ VIP blockchain hiện tại, bạn sẽ có cơ hội tận hưởng việc được blockchain mời tham gia vào nút tiếp theo được blockchain phát hiện để tiếp tục lưu trữ để kiếm lợi nhuận.",
      "Cho vay DeFi ETH",
      "Thông thường cao hơn và tự động bị loại khỏi APY của bạn",
      "Được xác định bởi nền tảng - đã sửa",
      "Nền tảng tập trung, không rõ ràng, không có cái nhìn sâu sắc về hoạt động nội bộ",
      "Thấp - có thể được thực hiện trong một vài cú nhấp chuột",
      "Hacking, Nền tảng mất khả năng thanh toán, Phá sản",
      "Phí thường thấp hơn và được liệt kê rõ ràng cùng với APY",
      "Được quyết định bởi thị trường - luôn biến động",
      "Nền tảng phi tập trung và minh bạch có mã thường là nguồn mở",
      "Cao – cần có sự quen thuộc với DeFi để hoàn tất quá trình",
      "hack",
      "Ba nền tảng được đề cập ở trên là các nền tảng tập trung tham gia vào tài chính tập trung (CeFi). Tùy chọn khác là các giao thức cho vay ETH tài chính phi tập trung (DeFi) hoạt động như một thị trường tiền điện tử cho người cho vay và người đi vay.",
      "Nền tảng DeFi duy trì thị trường tự động bằng cách sử dụng mã để xác định lãi suất cho vay và đi vay theo thời gian thực. Do đó, lãi suất cho vay ETH trên DeFi dao động dựa trên cung và cầu.",
      "Ưu điểm của DeFi là phí nền tảng thấp hơn nhiều. Và bởi vì các giao thức DeFi thường sử dụng mã nguồn mở và được quản lý bởi cộng đồng chủ sở hữu token nên các giao thức DeFi minh bạch hơn nhiều so với những người cho vay CeFi.",
      "Cho vay DeFi có thể mang lại lợi nhuận. Tuy nhiên, nó thường phức tạp hơn nên thường phù hợp hơn với những nhà giao dịch có kinh nghiệm.",
      "Câu hỏi: Thợ mỏ lấy ETH ở đâu?",
      "Trả lời: Người khai thác thu được ETH thông qua khai thác thanh khoản.",
      "Khai thác thanh khoản DeFi là một quá trình trong đó người dùng cung cấp thanh khoản cho nền tảng tài chính phi tập trung (DeFi) để đổi lấy phần thưởng. Chúng ta hãy xem xét kỹ hơn bài viết này để hiểu rõ hơn.",
      "Khai thác thanh khoản DeFi là gì?",
      "Khai thác thanh khoản DeFi là một quá trình trong đó người dùng cung cấp thanh khoản cho nền tảng tài chính phi tập trung (DeFi) để đổi lấy phần thưởng. Các nhà cung cấp thanh khoản (LP) có thể kiếm phí từ các giao dịch diễn ra trong nhóm, cũng như các mã thông báo từ chính nền tảng DeFi.",
      "Khai thác thanh khoản DeFi hoạt động như thế nào?",
      "Khai thác thanh khoản DeFi hoạt động bằng cách sử dụng một quy trình gọi là tạo thị trường tự động (AMM). AMM là hợp đồng thông minh tạo ra nhóm thanh khoản cho các token khác nhau. LP có thể thêm mã thông báo của họ vào nhóm thanh khoản và kiếm phí cho các giao dịch tại thời điểm đó trong nhóm.",
      "Lệ phí được thanh toán bằng các token đang được giao dịch trong nhóm. Ví dụ: nếu bạn thêm ETH và USDC vào nhóm thanh khoản, bạn sẽ kiếm được phí bằng ETH và USDC. Bạn cũng sẽ kiếm được token từ chính nền tảng DeFi. Những mã thông báo này thường được gọi là mã thông báo quản trị và chúng cung cấp cho bạn quyền biểu quyết trên nền tảng.",
      "Lợi ích của việc khai thác thanh khoản DeFi là gì?",
      "Có một số lợi ích khi tham gia khai thác thanh khoản DeFi. Đầu tiên, bạn có thể kiếm thu nhập thụ động bằng cách cung cấp tính thanh khoản cho nền tảng DeFi. Thứ hai, bạn có thể kiếm token từ chính nền tảng DeFi, nền tảng này có thể có tiềm năng tăng giá trị. Thứ ba, bạn có thể giúp làm cho nền tảng DeFi trở nên thanh khoản hơn, điều này có thể mang lại lợi ích cho tất cả người dùng nền tảng này.",
      "Khai thác thanh khoản DeFi có an toàn không?",
      "Khai thác thanh khoản DeFi không phải là không có rủi ro, nhưng nó có thể là một cách sinh lợi để kiếm thu nhập thụ động nếu bạn sẵn sàng chấp nhận những rủi ro đó. Điều quan trọng là bạn phải thực hiện nghiên cứu trước khi tham gia vào bất kỳ dự án khai thác thanh khoản DeFi nào và chỉ đầu tư số tiền mà bạn có đủ khả năng để mất.",
      "Khai thác thanh khoản DeFi là gì? Làm thế nào nó hoạt động? - Tôi hy vọng bài viết này có nhiều thông tin.",
    ],
  },
  check: {
    title: "Đăng nhập HÀNG NGÀY",
    rules: "QUY TẮC",
    point: "Tích lũy điểm",
    signin: "Đăng nhập",
    days: "Bạn đã đăng nhập ${consecutiveDays} ngày liên tiếp",
    reSigning: "Ký lại",
    day10: "10 ngày",
    day90: "90 ngày",
    rewards: "Phần thưởng",
    day10Rewards: "Phần thưởng đăng nhập 10 ngày liên tiếp",
    getIt: "Hiểu rồi",
    exchange: "Trao đổi",
    exchangeSub: "Trao đổi điểm",
    points: "điểm",
    share: "Chia sẻ nhiệm vụ",
    shareSub: "Chia sẻ phần thưởng nhiệm vụ",
    rank: "Danh sách xếp hạng điểm",
  },
};
