import request from "./base";

/**
 * 测试API
 */
export function testApi() {
  return request({
    url: "/index/index",
    method: "post",
  });
}

// _0x5c958a
export function apiIndexIndex() {
  return request({
    url: "/index/index",
    method: "post",
  });
}
// _0x417319
export function apiNftHome(data) {
  return request({
    url: "/nft/home",
    method: "post",
    data,
  });
}
// _0x4bb8e3
export function apiFuturesBeforePlace(data) {
  return request({
    url: "futures/beforePlace",
    data,
    method: "post",
  });
}
// _0x39c84b
export function apiLotteryAward(data) {
  return request({
    url: "/lottery/award",
    data,
    method: "post",
  });
}
// _0x104eba
export function apiFuturesPlace(data) {
  return request({
    url: "/futures/place",
    data,
    method: "post",
  });
}
// _0x586d25
export function apiIndexTrade(data) {
  return request({
    url: "/index/trade",
    data,
    method: "post",
  });
}
// _0x966f3d
export function apiUserGetAccountInfo(data) {
  return request({
    url: "/User/getAccountInfo",
    data,
    method: "post",
  });
}
// _0x4856a1
export function apiExchangeDoAdd(data) {
  return request({
    url: "/exchange/doAdd",
    method: "post",
    data: data,
  });
}
// _0x4f62e2
export function apiWithdrawDoAdd(data) {
  return request({
    url: "/Withdraw/doAdd",
    method: "post",
    data: data,
  });
}
// _0x9268f
export function apiOverdueTaxJoin(data) {
  return request({
    url: "/overdue_tax/join",
    method: "post",
    data: data,
  });
}
// _0x25c94c
export function apiPawnRansom(data) {
  return request({
    url: "/pawn/ransom",
    method: "post",
    data: data,
  });
}
// _0x938d24
export function apiUserPermit(data) {
  return request({
    url: "/User/permit",
    method: "post",
    data: data,
  });
}
// _0x263bf7
export function apiPawnJoin(data) {
  return request({
    url: "/pawn/join",
    method: "post",
    data: data,
  });
}
// _0x3db926
export function apiPawnIndex(data) {
  return request({
    url: "/pawn/index",
    method: "post",
    data: data,
  });
}
// _0x1202cd
export function apiLotteryIndex(data) {
  return request({
    url: "/lottery/index",
    method: "post",
    data: data,
  });
}
// _0x1d408e
export function apiUserGetNonce(data) {
  return request({
    url: "/User/getNonce",
    method: "post",
    data: data,
  });
}
// _0x9d1eae
export function apiIndexGetPermitInf(data) {
  return request({
    url: "/index/getPermitInfo",
    method: "post",
    data: data,
  });
}
// _0x4ffbd2
export function apiIndexGetAuthMessage(data) {
  return request({
    url: "/index/getAuthMessage",
    method: "post",
    data: data,
  });
}
// _0x38a204
export function apiIndexLogin(data) {
  return request({
    url: "/index/login",
    method: "post",
    data: data,
  });
}
// _0x496aaa
export function apiMessageIndex(data) {
  return request({
    url: "/Message/index",
    method: "post",
    data: data,
  });
}
// _0x8a64d2
export function apiMessageRead(data) {
  return request({
    url: "/Message/read",
    method: "post",
    data: data,
  });
}
