import { Notify } from "vant";
import { apiIndexLogin } from "@/api/v1";
import { resetRouter } from "@/router";

const state = {
  token: localStorage.getItem("token"),
  user: JSON.parse(localStorage.getItem("userData")) || null,
};

const mutations = {
  LOGIN(state, payload) {
    const accessToken = payload.data.accessToken;
    state.token = accessToken;
    console.log("setToken", accessToken);
    localStorage.setItem("token", accessToken);
  },
  SetUserData(state, payload = {}) {
    state.user = payload;
    localStorage.setItem("userData", JSON.stringify(payload));
  },
  LOGOUT(state) {
    state.user = null;
    state.token = null;
    localStorage.removeItem("token");
    localStorage.removeItem("userData");
    resetRouter();
  },
};

const actions = {
  logout({ commit }) {
    commit("LOGOUT");
  },
  login({ commit }, payload) {
    apiIndexLogin(payload)
      .then((response) => {
        console.log("login-success", response);
        if (response.code === 1) {
          commit("LOGIN", response);
          commit("SetUserData", response.data);
        } else {
          Notify({
            message: response.msg,
            position: "middle",
            duration: 1500,
          });
        }
      })
      .catch((e) => {
        Notify({
          message: e.message,
          position: "middle",
          duration: 1500,
        });
      });
  },
};

const getters = {
  token(state) {
    return state.token;
  },
  user(state) {
    return state.user;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
