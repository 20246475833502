import Vue from "vue";
import Vuei18n from "vue-i18n";
import en from "./en";
import vi from "./vi";

Vue.use(Vuei18n);

const i18n = new Vuei18n({
  locale: localStorage.getItem("lang") || "en",
  messages: {
    en,
    vi,
  },
});

/**
 * 默认实例
 */
export default i18n;
